import { Component, OnInit, Input } from '@angular/core';
import { DetailsEntity } from 'src/app/core/Search/Search';
import { Helpers } from 'src/app/core/_helpers/Helpers';

@Component({
  selector: 'app-result-detail-entity',
  templateUrl: './result-detail-entity.component.html',
  styleUrls: ['./result-detail-entity.component.scss']
})
export class ResultDetailEntityComponent implements OnInit {

  @Input() DetailsEntityView: DetailsEntity;
  Helpers: Helpers = new Helpers();
  CountryEntity;
  entityNameDetails;
  dateDetails;
  addressDetails;
  identificationDetails;
  associates;
  curDate;
  addressLinenotdefin = false;
  note;
  sourceDetails;
  sanctionsReferenceDetails;
  activeStatus;
  descriptions;
  constructor() { }

  ngOnInit() {
    this.addressLinenotdefin = false;
    this.LoadDetail();
    this.curDate = new Date();
  }

  LoadDetail() {
    this.CountryEntity = this.DetailsEntityView.countryDetails;
    this.entityNameDetails = this.DetailsEntityView.entityNameDetails;
    this.dateDetails = this.DetailsEntityView.dateDetails;
    this.addressDetails = this.DetailsEntityView.addressDetails.address[0];
    this.identificationDetails = this.DetailsEntityView.identificationDetails;
    this.associates = this.DetailsEntityView.associates;
    this.note = this.DetailsEntityView.watchlistContent.profileNotes.valueOf().replace('\\\n', '\n');
    this.sourceDetails = this.Helpers.ConvertDetails(this.DetailsEntityView.watchlistContent.sourceDetails.sources);
    // tslint:disable-next-line: max-line-length
    this.sanctionsReferenceDetails = this.Helpers.ConvertDetails(this.DetailsEntityView.watchlistContent.sanctionsReferenceDetails.sanctionsReference);
    this.activeStatus = this.DetailsEntityView.watchlistContent.activeStatus;
    this.descriptions = this.Helpers.ConvertDetails(this.DetailsEntityView.watchlistContent.descriptions.description);

  }

}
