import { SearchService } from '../../core/Search/search.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material';
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { RecurringSearchService } from '../../core/_service/recurring-search.service';
import { Datostabla } from '../../shared/datos-tabla';
@Component({
	selector: 'recurringsearch',
	templateUrl: './recurringsearch.component.html',
	styleUrls: ['./recurringsearch.component.scss'],
})
export class RecurringsearchComponent implements OnInit {
	showPDF2: boolean = false;
	Email;
	pid;
	mostrarSpinner: boolean = false;
	public urlPdf2;

	data: ResultsCSView[] = [];
	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;
	exampleDatabase: HttpDatabase | null;
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	Data: ResultsCSView[] = [];
	Columns: string[] = ['a', 'b', 'd', 'e'];
	paginas: number[] = [5, 10, 25, 50, 100];
	constructor(
		private service: RecurringSearchService,
		private ser: SearchService,
		private sanitizer: DomSanitizer,
		private _snackBar: MatSnackBar,
		private _httpClient: HttpClient
	) {}
	parametro: string = '';

	ngAfterViewInit() {
		this.mostrarSpinner = true;
		this.Filtrar();
	}

	ngOnInit() {
		/*	this.service.BusquedaPorUsuario().subscribe(
			(result) => {
				this.data = <ResultsCSView[]>result.listreturn;
				this.mostrarSpinner = false;
			},
			(error) => {
				this.mostrarSpinner = false;
				this._snackBar.open(JSON.stringify(error), 'Cerrar', {
					duration: 3000,
				});
				console.log(JSON.stringify(error));
			}
		);*/
		//this.Filtrar();
	}

	private Filtrar() {
		this.exampleDatabase = new HttpDatabase(this.service);
		this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					return this.exampleDatabase!.Filtrar(
						this.sort.active,
						this.sort.direction,
						this.paginator.pageSize,
						this.paginator.pageIndex + 1
					);
				}),
				map((data: any) => {
					this.mostrarSpinner = false;
					if (data != null) {
						this.isLoadingResults = false;
						this.isRateLimitReached = false;
						this.resultsLength = data.data.rowCount;
						let tabla = new ValueTabla();
						tabla.items = <ResultsCSView[]>data.data.result;
						tabla.total_count = data.data.rowCount;

						let index = this.paginator._displayedPageSizeOptions.findIndex(
							(x) => x == data.data.rowCount
						);
						if (index < 0)
							this.paginator._displayedPageSizeOptions.push(data.data.rowCount);

						return tabla;
					} else {
						let tabla: ValueTabla;
						return tabla;
					}
				}),
				catchError((msj) => {
					this.mostrarSpinner = false;
					console.log(msj);
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			)
			.subscribe((data) => (this.Data = (<ValueTabla>data).items));
	}

	public showDetail2(pid: string, searchmodeljson: any) {
		this.showPDF2 = false;
		this.ser
			.ShowPDF3(pid, this.paginator.pageSize, this.paginator.pageIndex + 1)
			.subscribe((resp) => {
				this.urlPdf2 = this.sanitizer.bypassSecurityTrustResourceUrl(
					window.URL.createObjectURL(
						new Blob([resp], { type: 'application/pdf' })
					)
				);

				this.showPDF2 = true;
			});
	}

	public showEmail2(pid: string, searchmodeljson: any): void {
		this.pid = pid;
		localStorage.setItem('DataSearch', searchmodeljson);
	}

	public SendEmail2(): void {
		this.ser
			.SendEmail2(
				this.paginator.pageSize,
				this.paginator.pageIndex + 1,
				this.Email
			)
			.subscribe(
				(r) => {
					if (r.send) {
						this._snackBar.open(JSON.stringify(r.message), 'Cerrar', {
							duration: 3000,
						});
					} else {
						this._snackBar.open(JSON.stringify(r.message), 'Cerrar', {
							duration: 3000,
						});
					}
				},
				(error) => {
					this._snackBar.open(JSON.stringify(error), 'Cerrar', {
						duration: 3000,
					});
					console.log(JSON.stringify(error));
				}
			);
	}
}

export interface ResultsCSView {
	name: string;
	coincidence: boolean;
	change: boolean;
	typeofsearch: number;
	searchmodeljson: string;
}

export class ValueTabla {
	items: ResultsCSView[];
	total_count: number;
}

export interface modelo {
	nombre: string;
	id: string;
}

export class HttpDatabase {
	constructor(private serv: RecurringSearchService) {}
	public Filtrar(
		sort: string,
		order: string,
		PageSize: number,
		page: number
	): Observable<Datostabla> {
		return this.serv.BusquedaPorUsuario2(sort, order, PageSize, page);
	}
}
