import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/Authentication.service';
import { ModalService } from 'src/app/core/_service/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/core/_helpers/Language';
import * as firebase from 'firebase';
import { codigosstripe } from '../../urls/codigosstripe';
import { FirebaseAuth } from '@angular/fire';
import { AngularFireAuth } from '@angular/fire/auth';
import { Global } from 'src/app/core/_service/global';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Output() public language = new EventEmitter<string>();
  @Output() public componentEvent = new EventEmitter<Number>();

  public role;
  view: boolean;

  leng;
  plan;
  planStry;
  user;
  constructor(private router: Router,
    public auth: AuthenticationService, private modalService: ModalService,
    private translate: TranslateService, private lenguaje: Language, public planSt: codigosstripe,
    private afAuth: AngularFireAuth, private varGlo: Global) {

    this.planSt = planSt;
  }

  ngOnInit() {
    this.translateLeng();
    this.iniProfile();
    this.plan = localStorage.getItem('plan');
    this.user = localStorage.getItem('uid');
  }

  Logout() {
    this.auth.logout()
      .then((user) => {
        localStorage.clear();
        this.router.navigate(['/login']);
      }, (error) => {
        alert(error.message);
      });
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  translateLeng() {
    const lang = localStorage.getItem('language');
    this.leng = lang;
    this.lenguaje.IniLenguaje(lang);
  }

  changeLeng(val) {
    this.auth.changeLanguaje(val).subscribe(result => {
      console.log(result);
    });
    this.leng = val;
    this.translate.use(val);
    localStorage.setItem('language', val);
    this.language.emit(val);
    console.log('si entra');
  }

  iniProfile() {
    const id = this.afAuth.auth.currentUser.uid;
    firebase.database().ref('users/' + id).once('value').then((snapshot) => {
      var username = (snapshot.val() && snapshot.val() != null) || 'Anonymous';
      if (snapshot.val() !== null) {
        if (typeof snapshot.val().language !== 'undefined') {
          const rol = snapshot.val().rol;
          this.role = rol.admin;
        }
      } else {

      }

    });
  }
  CantVal() {
    this.varGlo.SearchCount();
  }
  ShowCallCenter() {
    this.componentEvent.emit(1);
  }
}
