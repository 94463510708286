export class User {
    uid: string;
    email?: string;
    photoURL?: string;
    displayName?: string;
    displayFirstName?: string;
    displayLastName?: string;
    favoriteColor?: string;
    token?: string;
    parentUser?: string;
    roles?: Roles;
    company?: string;
    position?: string;
    direction?: string;
    country?: string;
    phone?: string;
    firstName?: string;
    lastName?: string;
    city?: string;
}

export class Roles {
    admin?: boolean;
    user?: boolean;
}
