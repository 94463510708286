// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	firebase: {
		apiKey: 'AIzaSyBeB2hIaB3xgSOAiUh3xkNFyGwflC_NmMc',
		authDomain: 'zabio-97c8a.firebaseapp.com',
		databaseURL: 'https://zabio-97c8a.firebaseio.com',
		projectId: 'zabio-97c8a',
		storageBucket: 'zabio-97c8a.appspot.com',
		messagingSenderId: '431828437710',
	},

	//production

    
	apiphp: 'https://payment.riskyid.com/api.php',
	apiback: 'https://api.riskyid.com/api/Account',
	apibackdavid: 'https://api.riskyid.com',
	apiphp2: 'https://payment.riskyid.com/api2.php',
	urlRedirect: 'https://app.riskyid.com/#/login',
	
	
		/*
	//local
	apiphp: 'https://payment.riskyid.com/api.php',
	apiback: 'http://localhost:50810/api/Account',
	apibackdavid: 'http://localhost:50810',
	apiphp2: 'https://payment.riskyid.com/api2.php',
	urlRedirect: 'http://localhost:4200/#/login',
   		*/

	//Desarrollo y stripe desarrollo
	/*
	apiphp: 'https://devpayment.riskyid.com/api.php',
	apiback:  'https://devapi.riskyid.com/api/Account',
	apibackdavid: 'https://devapi.riskyid.com',
	apiphp2: 'https://devpayment.riskyid.com/api2.php',
	urlRedirect: 'https://devapp.riskyid.com/#/login',
	
	*/
/*
	//Desarrollo y stripe en production por petici�n de Rhonald
		
	apiphp: 'https://payment.riskyid.com/api.php',
	apiback:  'https://devapi.riskyid.com/api/Account',
	apibackdavid: 'https://devapi.riskyid.com',
	apiphp2: 'https://payment.riskyid.com/api2.php',
	urlRedirect: 'https://devapp.riskyid.com/#/login',
	
		*/
	
};
