import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filtrarTablaRecurrente',
})
export class FiltrarTablaRecurrentePipe implements PipeTransform {
	transform(value: any, arg: any): any {
		const resultado = [];
		if (arg === '') return value;
		for (const item of value) {
			if (item.name.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
				resultado.push(item);
			}
		}

		return resultado;
	}
}
