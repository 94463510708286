import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AuthenticationService } from '../../../../core/authentication/Authentication.service';
import { FlashMessagesService } from 'angular2-flash-messages';

import { User } from '../../../../core/authentication/user';
import { MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
	Password: string;
	PasswordConfirm: string;
	Email: string;
	DisplayFirstName: string;
	DisplayLastFirstName: string;
	Error: string;
	DisplayFirstNameText;
	DisplayLastNameText;
	DisplayCompanyText;
	DisplayPositionText;
	DisplayCountryText;
	DisplayPhoneText;
	DisplayDirectionText;
	DisplayCityText;

	user: User = new User();
	plan: string;
	planprecio: string;
	constructor(
		private router: Router,
		private auth: AuthenticationService,
		public flashMessage: FlashMessagesService,
		private _snackBar: MatSnackBar,
		private rutaActiva: ActivatedRoute,
		private http: HttpClient,
		private translate: TranslateService
	) {
		translate.setDefaultLang('es');
	}

	ngOnInit() {}
	Signup() {
		this.user.displayName =
			this.DisplayFirstNameText + ' ' + this.DisplayLastNameText;
		this.user.displayFirstName = this.DisplayFirstNameText;
		this.user.displayLastName = this.DisplayLastNameText;
		this.user.email = this.Email;

		this.user.company = this.DisplayCompanyText;
		this.user.position = this.DisplayPositionText;
		this.user.country = this.DisplayCountryText;
		this.user.phone = this.DisplayPhoneText;
		this.user.direction = this.DisplayDirectionText;
		this.user.city = this.DisplayCityText;
		this.user.roles = { user: false, admin: true };
		this.user.parentUser = 'is padre';
		this.plan = this.rutaActiva.snapshot.params.plan;
		this.planprecio = this.rutaActiva.snapshot.params.planprecio;
		this.auth
			.registerUser(this.user, this.Password, false)
			.then((r) => {
				this.auth.registerUserBack(this.user, this.Password, false).subscribe(
					(res: any) => {
						console.log(res['id']);
						if (res['id'] !== '') {
							const id = localStorage.getItem('uidCreat');

							this.user.uid = id;
							this.auth.RecordUserData(this.user);

							localStorage.setItem('uid', id);
							this.auth
								.loginserver(localStorage.getItem('uidCreat'))
								.subscribe((result) => {
									localStorage.setItem('plan', this.plan);

									localStorage.setItem('TokenServe', result['token']); //this.user.email, this.user.displayName
									this.router.navigate([
										'/checkout',
										this.plan,
										this.user.email,
										this.user.displayName,
										r,
										this.planprecio,
										this.user.direction,
										this.user.phone,
										this.user.company,
										this.user.country,
									]);
									// console.log({ html: 'Se a agregado correctamente el nivel de educación' });
								});
						} else {
							console.log(res.errors);
						}
					},
					(err) => {
						console.log(err);
						this.auth.eliminar();
					}
				);
			})
			.catch((err) => {
				this._snackBar.open(err, 'Cerrar', {
					duration: 3000,
				});
			});
	}
}
