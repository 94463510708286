import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})

export class FileUploadComponent implements OnInit {
  @Output() FileUploaded = new EventEmitter<any>();
  @Output() FileUploadedName = new EventEmitter<any>();
  @Input() typesearch: number;

  nombre: any;
  errorTrue: boolean;
  file: File;
  selectedFile;
  photoURL;
  panelOpenState = false;

  constructor(private _snackBar: MatSnackBar, private http: HttpClient) { }
  ngOnInit() {
    this.nombre = 'Ningún archivo seleccionado';
    this.errorTrue = true;
  }



  handleFileInput(imageInput: any) {
    this.file = imageInput.item(0);
    const filePath = this.file.name;
    const allowedExtensions = /(\.xlsx|\.xls)$/i;

    if (!allowedExtensions.exec(filePath)) {
      this.errorTrue = false;
      this.nombre = 'Ningún archivo seleccionado';
    } else {
      this.errorTrue = true;
      this.nombre = this.file.name;
      //  const formData = new FormData();
      //    formData.append('doc', this.file);
      // this.http.post('http://localhost:50810/test/doc', formData)
      // .subscribe(data => {
      //  console.log(data);
      // })
    }
  }

  DownloadExcel() {
    if (this.typesearch === 1) {
      window.open('assets/Formatos/RiskyID_Batch_Lote.xlsx', '_blank');
    } else {
      window.open('assets/Formatos/RiskyID_ID_types.xlsx', '_blank');
    }
  }
  DownloadInstructivo() {
      window.open('assets/Formatos/Instructivo_Busqueda_Masiva.pdf', '_blank');
  }
  ocultar() {
    this.errorTrue = true;
  }
  enviarArchivo() {
    if (this.nombre === 'Ningún archivo seleccionado') {
      this.FileUploaded.emit(this.nombre);
    } else {
      this.FileUploaded.emit(this.file);
      this.nombre = 'Ningún archivo seleccionado';
    }
  }
}
