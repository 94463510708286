export class Search {
	FirstName: string;
	SecondName: string;
	Surname: string;
	Entity: string;
	Birthdate: string;
	Year: string;
	DefineSearchType: string;
	filterRegion: string;
	CategoryPep: string;
	SpecialCategory: string;
	SearchPercentage: string;
}

export class IdType {
	idTypeKey: string;
	idTypeValue: string;
	DateLocal?: string;
}

export class DetailsPerson {
	pid: string;
	date: string;
	gender: string;
	deceased: string;
	personNameDetails: {
		personNames: [
			{
				personNameValue: {
					nameType: string;
					firstName: string;
					middleName: string;
					surname: string;
					originalScriptName: string;
				};
				personNamesGroup: {
					personNames: [
						{
							personNameValue: {
								nameType: string;
								firstName: string;
								middleName: string;
								surname: string;
								originalScriptName: string;
							};
						}
					];
				};
			}
		];
		personNamesGroup: {
			personNames: {
				personNameValue: {
					nameType: string;
					firstName: string;
					middleName: string;
					surname: string;
					originalScriptName: string;
				};
				personNamesGroup: {
					personNames: [];
				};
			};
		};
	};
	countryDetails: {
		countryValues: {
			countryType: string;
			country: {
				djiiRegionCode: string;
				iso2CountryCode: string;
				iso3CountryCode: string;
				isTerritory: string;
				countryCrofile: string;
				value: string;
			};
		};
	};
	dateDetails: {
		dates: [
			{
				day: string;
				month: string;
				year: string;
				dateType: string;
			}
		];
	};
	identificationDetails: {
		identification: [
			{
				identificationType: string;
				value: string;
			}
		];
	};
	imageDetails: {
		imageUrl: [
			{
				url: string;
			}
		];
	};
	watchlistContent: {
		activeStatus: string;
		profileNotes: string;
		descriptions: {
			description: [
				{
					description1: string;
				}
			];
		};
		roleDetails: {
			roles: [
				{
					roleType: string;
					occupationTitleAttribute: {
						occupationCategory: string;
						sinceDay: string;
						sinceMonth: string;
						sinceYear: string;
						value: string;
					};
				}
			];
		};
		sourceDetails: {
			sources: [
				{
					type: string;
					value: string;
				}
			];
		};
	};
	associates: {
		associate: [
			{
				peid: string;
				primaryName: string;
				relationship: string;
				description1: string;
				description2: string;
				ex: boolean;
			}
		];
	};
}

export class DetailsEntity {
	pid: string;
	date: string;
	subsidiary: string;
	entityNameDetails: {
		entityNames: [
			{
				entityNameValue: {
					nameType: string;
					entityName: string;
					suffix: string;
				};
			}
		];
	};
	dateDetails: {
		dates: [
			{
				day: string;
				month: string;
				year: string;
				dateType: string;
			}
		];
	};
	countryDetails: {
		countryValues: [
			{
				countryType: string;
				country: {
					djiiRegionCode: string;
					iso2CountryCode: string;
					iso3CountryCode: string;
					isTerritory: string;
					countryCrofile: string;
				};
			}
		];
	};
	addressDetails: {
		address: [
			{
				addressLine: string;
				city: string;
				region: string;
				zipCode: string;
				country: {
					djiiRegionCode: string;
					iso2CountryCode: string;
					iso3CountryCode: string;
					isTerritory: string;
					countryCrofile: string;
				};
				addressUrl: string;
			}
		];
	};
	identificationDetails: {
		identification: [
			{
				identificationType: string;
				value: string;
			}
		];
	};
	watchlistContent: {
		activeStatus: string;
		profileNotes: string;
		descriptions: {
			description: [
				{
					description1: string;
					description2: string;
					description3: string;
				}
			];
		};
		roleDetails: {
			roles: [
				{
					roleType: string;
					occupationTitleAttribute: {
						occupationCategory: string;
						sinceDay: string;
						sinceMonth: string;
						sinceYear: string;
						value: string;
					};
				}
			];
		};
		sourceDetails: {
			sources: [
				{
					type: string;
					value: string;
				}
			];
		};
		sanctionsReferenceDetails: {
			sanctionsReference: [
				{
					description2: string;
					listProviderName: string;
					sinceMonth: string;
					sinceYear: string;
					value: string;
				}
			];
		};
	};
	associates: {
		associate: [
			{
				peid: string;
				primaryName: string;
				relationship: string;
				description1: string;
				description2: string;
				ex: string;
			}
		];
	};
}
