import { Component, OnInit, Input } from '@angular/core';
import { Timezone } from 'src/app/core/_helpers/timezones';
import { AuthenticationService } from 'src/app/core/authentication/Authentication.service';
import { User } from '../../../core/authentication/user';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { Language } from 'src/app/core/_helpers/Language';
import * as firebase from 'firebase/app';
import { stringify } from '@angular/compiler/src/util';
import { SearchService } from 'src/app/core/Search/search.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Global } from 'src/app/core/_service/global';
import { FlashMessagesService } from 'angular2-flash-messages';
import { codigosstripe } from 'src/app/shared/urls/codigosstripe';

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit {

  selectedFile: ImageSnippet;
  file: File;

  constructor(public auth: AuthenticationService, private storage: AngularFireStorage,
    private _snackBar: MatSnackBar, private languaje: Language, private search: SearchService,
    private afAuth: AngularFireAuth, private VarGlobal: Global,
    public flashMessage: FlashMessagesService, private codigoStr: codigosstripe) {
    this.perfilUser();
    this.planstrip = codigoStr.ultra;
  }

  @Input() public lenguaje: string;
  public Email: string;
  public Token: string;
  public isLogin: boolean;
  public nombreUsuario: string;
  dataTime = new Timezone().getTimezone();
  private uid: string;
  public photoURL: string;
  uploadPercent: Observable<number>;
  urlImage: Observable<string>;
  action: number = 1;
  countVerif;
  user: User;

  FirstName: string;
  LastName: string;
  Company: string;
  Country: string;
  Direction: string;
  Phone: string;
  Position: string;
  City: string;
  plan;
  planstrip;
  // selectedFile: File;


  ngOnInit() {
    this.lenguajeIni();
    this.plan = localStorage.getItem('plan');
  }

  onFileChanged(imageInput: any) {
    this.file = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, this.file);

      console.log(this.selectedFile);
      this.photoURL = this.selectedFile.src;
    });

    reader.readAsDataURL(this.file);
  }

  perfilUser() {
    // const id = localStorage.getItem('uid');
    const id = this.afAuth.auth.currentUser.uid;
    // this.nombreUsuario = this.afAuth.auth.currentUser.displayName;
    this.Email = this.afAuth.auth.currentUser.email;
    this.uid = id;
    this.auth.getProfileBack(id).subscribe(result => {
      this.user = result;
      this.FirstName = this.user.firstName;
      this.LastName = this.user.lastName;
      this.Company = this.user.company;
      this.Position = this.user.position;
      this.Phone = this.user.phone;
      this.Country = this.user.country;
      this.Direction = this.user.direction;
      this.City = this.user.city;
    });
  }

  onSubmit() {
    const data: User = {
      uid: this.uid,
      firstName: this.FirstName,
      lastName: this.LastName,
      country: this.Country,
      company: this.Company,
      direction: this.Direction,
      position: this.Position,
      phone: this.Phone,
      city: this.City
    };
    this.auth.PutProfileBack(data).subscribe(result => {
      if (result['status'] === true) {
        this._snackBar.open('Se ha actualizado el nombre de perfil', 'Cerrar', {
          duration: 3000
        });
        this.flashMessage.show('Se ha actualizado el perfil!', { cssClass: 'alert alert-success', timeout: 3000 });
      }
    });
  }

  changePassword() {
    this.action = 2;
  }

  resultComp($event) {
    this.action = $event;
  }

  lenguajeIni() {
    this.languaje.IniLenguaje(this.lenguaje);
  }
}
