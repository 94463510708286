import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-prueba',
  templateUrl: './prueba.component.html',
  styleUrls: ['./prueba.component.scss']
})
export class PruebaComponent implements OnInit {
  title = 'archivo';
  public files: NgxFileDropEntry[] = [];

  constructor(private http:HttpClient) { }

  ngOnInit() {
  }
  public dropped(files: NgxFileDropEntry[]){
    this.files = files;
    for (const droppedFile of files)
    {
      if (droppedFile.fileEntry.isFile)
      {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) =>{
          const formData = new FormData()
          formData.append('doc', file);
          this.http.post('http://localhost:50810/test/doc', formData)
          .subscribe(data => {
            console.log(data);
          })
          /* 
            const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

           this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          */
        })
      }else
      {
           // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }
  public fileOver(event)
  {
    console.log(event);
  }
 
  public fileLeave(event)
  {
    console.log(event);
  }

}
