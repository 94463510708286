import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchService } from 'src/app/core/Search/search.service';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
	selector: 'app-list-verifications',
	templateUrl: './list-verifications.component.html',
	styleUrls: ['./list-verifications.component.scss'],
})
export class ListVerificationsComponent implements OnInit {
	constructor(
		private search: SearchService,
		private translate: TranslateService
	) {}

	mostrarSpinner: boolean = false;
	result;
	displayedColumns: string[] = [
		'position',
		'name',
		'email',
		'weight',
		'symbol',
	];
	dataSource;
	total = 0;
	minDate: Date;
	maxDate: Date;
	bsDaterangepicker;
	public ComponentView = 0;
	Daterangepickertxt = 'Busqueda por rango de fecha';
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	ngOnInit() {
		const desde = new Date('2019-01-01');
		const hasta = new Date('2020-02-17');
		this.maxDate = new Date();
		this.minDate = new Date(2019, 8, 1);
		this.maxDate.setDate(this.maxDate.getDate());
		this.listVerifications(this.minDate, this.maxDate);
		this.translate.use(localStorage.getItem('language'));
	}

	private listVerifications(desde, hasta) {
		this.mostrarSpinner = true;
		this.search.GetListVerificatins(desde, hasta).subscribe(
			(res) => {
				this.dataSource = new MatTableDataSource<PeriodicElement>(
					<PeriodicElement[]>res['data']
				);
				this.dataSource.paginator = this.paginator;
				this.total = res['totalVer'];
				this.mostrarSpinner = false;
			},
			(e) => {
				this.mostrarSpinner = false;
				console.log(JSON.stringify(e, null, 5));
			}
		);
	}
	onValueChange(value: Date): void {
		this.listVerifications(value[0], value[1]);
	}
	limpiar() {
		const desde = new Date('2019-01-01');
		const hasta = new Date('2020-02-17');

		this.listVerifications(desde, hasta);
		this.bsDaterangepicker = '';
	}

	showHeptcenter($event) {
		this.ComponentView = $event;
	}

	changeLeng($event) {
		this.translate.use($event);

		localStorage.setItem('language', $event);
	}
}

export interface PeriodicElement {
	firstName: string;
	lastName: string;
	email: string;
	searchesConsumed: string;
	remainingSearches: string;
}
