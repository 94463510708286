import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/home/pages/login/login.component';
import { SignupComponent } from './modules/home/pages/signup/signup.component';
import { ForgotComponent } from './modules/home/pages/forgot/forgot.component';
import { AfterLoginComponent } from './modules/home/pages/afterlogin/afterlogin.component';
import { AuthGuard } from './core/authentication/auth.guard';
import { ThankYouPageComponent } from './modules/home/pages/thank-you-page/thank-you-page.component';
import { WorkspaceSeetingsComponent } from './modules/workspace/workspace-seetings/workspace-seetings.component';
import { ProfileSettingsComponent } from './modules/profile/profile-settings/profile-settings.component';
import { SettingsAllUserComponent } from './modules/profile/settings-all-user/settings-all-user.component';
import { UsersComponent } from './modules/profile/users/users.component';
import { CallCenterComponent } from './modules/home/pages/call-center/call-center.component';
import { UpgradeComponent } from './modules/account/upgrade/upgrade.component';
import { PackComponent } from './modules/account/pack/pack.component';
import { PaymentmethodComponent } from './modules/account/paymentmethod/paymentmethod.component';
import { PlanesMembresiaComponent } from './modules/home/pages/planes-membresia/planes-membresia.component';
import { CheckoutComponent } from './modules/home/pages/checkout/checkout.component';
import { AgradecimientoComponent } from './modules/home/pages/agradecimiento/agradecimiento.component';
import { FileUploadComponent } from './modules/search/masssive-search/file-upload/file-upload.component';
import { ListVerificationsComponent } from './modules/account/list-verifications/list-verifications.component';
import { RecurringsearchComponent } from './modules/recurringsearch/recurringsearch.component';
import { RecurringsearchreportComponent } from './modules/report/recurringsearchreport/recurringsearchreport.component';
const routes: Routes = [
	{ path: '', redirectTo: '/login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent },
	{ path: 'signup/:plan/:planprecio', component: SignupComponent },
	{ path: 'forgot', component: ForgotComponent },
	{ path: 'upgrade', component: UpgradeComponent, canActivate: [AuthGuard] },
	{
		path: 'checkout/:plan/:email/:name/:uid/:planprecio/:direccion/:telefono/:empresa/:pais',
		component: CheckoutComponent,
	},
	{ path: 'pack', component: PackComponent, canActivate: [AuthGuard] },
	{
		path: 'agradecimiento/:mensaje/:plan',
		component: AgradecimientoComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'paymentmethod',
		component: PaymentmethodComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'afterlogin',
		component: AfterLoginComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'Thank-You',
		component: ThankYouPageComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'workspace/settings',
		component: WorkspaceSeetingsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'profile-settings',
		component: ProfileSettingsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'call-center',
		component: CallCenterComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'list-verifications',
		component: ListVerificationsComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'file-upload',
		component: FileUploadComponent,
		canActivate: [AuthGuard],
	},
	{ path: 'recurring-list', component: RecurringsearchComponent },
	{
		path: 'recurring-search-report',
		component: RecurringsearchreportComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'users',
		component: UsersComponent,
		canActivate: [AuthGuard],
		children: [{ path: 'list-User', component: SettingsAllUserComponent }],
	},
	{ path: 'planes-membresia', component: PlanesMembresiaComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
