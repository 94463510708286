import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { SearchService } from 'src/app/core/Search/search.service';
import { DetailsPerson, DetailsEntity } from 'src/app/core/Search/Search';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ResultDetailPersonComponent } from '../result-detail-person/result-detail-person.component';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-result-detail',
  templateUrl: './result-detail.component.html',
  styleUrls: ['./result-detail.component.scss']
})
export class ResultDetailComponent implements OnInit {

  constructor(private service: SearchService, private domSanitizer: DomSanitizer, private _snackBar: MatSnackBar) { }

  DetailsPerson: DetailsPerson;
  DetailsEntity: DetailsEntity;
  @Input() Person;
  showTypeSearch = 0;
  ResultDetail;

  ngOnInit() {
    this.LoadDetailPerson();
  }

  LoadDetailPerson() {
    // console.log(this.Person['pid']);

    const promise = new Promise((resolve, reject) => {
      this.service.DetailPersonEntity(this.Person['pid'])
        .toPromise()
        .then(
          res => {
            this.ResultDetail = res;
            if (typeof this.Person['recordType'] !== 'undefined') {
              if (this.Person['recordType'] === 'ENTITY') {
                this.DetailsEntity = <DetailsEntity>res;
                this.showTypeSearch = 2;
              } else {
                this.DetailsPerson = <DetailsPerson>res;
                this.showTypeSearch = 1;
              }

            } else {
              this.showTypeSearch = +localStorage.getItem('showTypeSearch');
              if (this.showTypeSearch === 1) {
                this.DetailsPerson = <DetailsPerson>res;
              } else {
                this.DetailsEntity = <DetailsEntity>res;
              }
            }
            resolve();
          },
          msg => { // Error
            reject(msg);
          }
        );
    });

    return promise;
  }

  transform(url): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
