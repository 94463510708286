import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-file-upload-recurring',
  templateUrl: './file-upload-recurring.component.html',
  styleUrls: ['./file-upload-recurring.component.scss']
})
export class FileUploadRecurringComponent implements OnInit {

  @Output() FileUploaded = new EventEmitter<any>();
  @Output() FileUploadedName = new EventEmitter<any>();
  @Input() typesearch: number;

  constructor(private _snackBar: MatSnackBar, private http: HttpClient) { }

  ngOnInit() {
    this.nombre="Ningún archivo seleccionado";
    this.errorTrue= true;
  }

  nombre: any;
  errorTrue: boolean;
  file: File;
  selectedFile;
  photoURL;

  handleFileInput(imageInput: any) {
    this.file = imageInput.item(0);
    var filePath = this.file.name;
    var allowedExtensions = /(\.xlsx|\.xls)$/i;

    if(!allowedExtensions.exec(filePath)){
      this.errorTrue=false;
      this.nombre="Ningún archivo seleccionado";
    }else{
      this.errorTrue= true;
      this.nombre= this.file.name;
      const formData = new FormData();
      /*formData.append('doc', this.file);
      this.http.post('http://localhost:50810/test/doc', formData)
          .subscribe(data => {
            console.log(data);
      })*/
    }
  }
  DownloadExcel(){
    if (this.typesearch == 1){
      window.open("https://app.riskyid.com/Formatos/RiskyID_Persona.xlsx", "_blank");
    }else {
      window.open("https://app.riskyid.com/Formatos/RiskyID_ID_types.xlsx", "_blank");
    }
  }
  ocultar(){
    this.errorTrue= true;
  }
  enviarArchivo(){
    if (this.nombre == "Ningún archivo seleccionado"){
      this.FileUploaded.emit(this.nombre);
    }else{
      this.FileUploaded.emit(this.file);
      this.nombre= "Ningún archivo seleccionado";
    }
    }
}
export class ImageSnippet{
    constructor(public src: string, public file: File) {
      
    }
}
