import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { MatSnackBar } from "@angular/material";
import { ModalService } from "src/app/core/_service/modal.service";
import { SelectFiltreService } from "src/app/core/SelectFiltre/select-filtre.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FileUploadComponent } from "../masssive-search/file-upload/file-upload.component";
import { DatePipe } from "@angular/common";
import { environment } from "src/environments/environment";
import { SearchService } from "src/app/core/Search/search.service";
@Component({
  selector: "app-masssive-search",
  templateUrl: "./masssive-search.component.html",
  styleUrls: ["./masssive-search.component.scss"],
})
export class MasssiveSearchComponent implements OnInit {
  @ViewChild(FileUploadComponent, { static: false })
  childFile!: FileUploadComponent;
  @Output() showresult = new EventEmitter<boolean>();
  @Output() public componentEvent = new EventEmitter<Number>();
  @Output() public result = new EventEmitter<any>();
  @Output() public TypeSearch = new EventEmitter<number>();

  @Input() NombreArchivo;
  @Input() ArchivoRecibido;
  @Input() fileupload;

  ShowSearchData2 = 1;
  DefineSearch2 = 2;
  Percentage2 = 80;
  ListItems;
  Limpio;
  @Output() public ShowSearchId = new EventEmitter<Number>();
  ShowSearchIdRadio = 1;
  country2;
  category2;
  specialCategory2;
  DefineSearchTypeData2;
  FileUpload;

  recibido;
  angForm;
  idTypeKey = "";

  constructor(
    private modalService: ModalService,
    private servicefilter: SelectFiltreService,
    private fb: FormBuilder,
    private service: SelectFiltreService,
    private http: HttpClient,
    private datepipe: DatePipe,
    private serviceSearch: SearchService,
    private _snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  public mostrarMensaje(indeceMultiIdioma: string, tiempo: number) {
    this._snackBar.open(this.translate.instant(indeceMultiIdioma), "", {
      duration: tiempo,
    });
  }

  ngOnInit() {
    this.ShowSearchId.emit(1);
    this.fromLoad();
    this.loadProfileIdType();
    this.loadFilter();
  }
  httpOptions = {
    headers: new HttpHeaders({
      Authorization: "Bearer " + localStorage.getItem("TokenServe"),
      responseType: "arraybuffer",
    }),
  };

  ngAfterViewInit() {
    // this.FileUpload = this.childFile.file;
  }

  fromLoad() {
    this.angForm = new FormGroup({
      ShowSearchData2: new FormControl(1),
      idTypeKey: new FormControl(""),
      DefineSearch2: new FormControl(2),
      Percentage2: new FormControl(),
      filterRegion2: new FormControl(""),
      CategoryPep2: new FormControl(""),
      SpecialCategory2: new FormControl(""),
      fileupload: new FormControl(""),
    });
  }

  loadProfileIdType() {
    this.service.TypeSearch(9).subscribe((result) => {
      this.ListItems = result;
      localStorage.setItem("IdTypeSearch", JSON.stringify(this.ListItems));
    });
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  clickData2(event) {
    console.log(event.value);
    if (event.value === 1) {
      this.ShowSearchId.emit(1);
      this.ShowSearchIdRadio = 1;
    } else if (event.value === 2) {
      this.ShowSearchId.emit(2);
      this.ShowSearchIdRadio = 2;
    }
  }

  change2(event) {
    this.Percentage2 = event.value;
    if (this.Percentage2 <= 60) {
      // this.DefineSearch2 = 2;
    }
    if (this.Percentage2 >= 61 && this.Percentage2 < 90) {
      //  this.DefineSearch2 = 1;
    }
    if (this.Percentage2 >= 90) {
      //this.DefineSearch2 = 0;
    }
  }

  radioChange2(event) {
    this.Percentage2 = event.value;
    if (event.value === 0) {
      this.Percentage2 = 100;
    }
    if (event.value === 1) {
      this.Percentage2 = 80;
    }
    if (event.value === 2) {
      this.Percentage2 = 60;
    }
  }
  cleanForm() {
    // this.angForm.reset();
    this.angForm.setValue({
      ShowSearchData2: 1,
      idTypeKey: "",
      DefineSearch2: 2,
      Percentage2: 80,
      filterRegion2: "",
      CategoryPep2: "",
      SpecialCategory2: "",
      fileupload: "",
    });
    this.DefineSearch2 = 2;
    // this.Percentage2 = 100;
    // this.ShowSearchId.emit(1);
    this.ShowSearchIdRadio = 1;
    this.NombreArchivo = "";
    this.ArchivoRecibido = undefined;
  }
  sendshowresult(mensaje: boolean) {
    this.showresult.emit(mensaje);
  }
  onSubmit() {
    this.serviceSearch.Verifications(0).subscribe((res) => {
      if (res > 0) {
        if (this.angForm.valid) {
          this.procesar();
        } else {
          this.mostrarMensaje("MissingDataRequired.texto", 2000);
        }
      } else {
        this.mostrarMensaje("NoQuotasAvailable.texto", 10000);
      }
    });
  }

  procesar() {
    debugger;
    if (this.ArchivoRecibido === undefined) {
      this.mostrarMensaje("NoFileSelected.texto", 2000);
    } else {
      const formData = new FormData();

      this.TypeSearch.emit(this.ShowSearchData2);

      switch (this.ShowSearchData2) {
        case 1:
          const dataCountry = <any[]>(
            JSON.parse(localStorage.getItem("country"))
          );
          const dataCategory = <any[]>(
            JSON.parse(localStorage.getItem("category"))
          );
          const dataSpecialCategory = <any[]>(
            JSON.parse(localStorage.getItem("specialCategory"))
          );

          const dataSend = JSON.stringify(this.loadInicialiceForm());

          localStorage.setItem("showTypeSearch", "1");

          formData.append("DocExcel", this.fileupload);
          formData.append("DataSearch", dataSend);
          this.http
            .post(
              environment.apibackdavid + "/api/Search/doc",
              formData,
              this.httpOptions
            )
            .subscribe((data) => {
              // console.log(data);
              // this.recibido = data;
              this.result.emit(data);
              this.showresult.emit(true);
              this.LoadDataCombo(
                dataCountry,
                dataCategory,
                dataSpecialCategory
              );
              this.cleanForm();
            });
          break;
        case 2:
          const searchID = {
            idTypeKey: this.angForm.controls["idTypeKey"].value,
            idTypeValue: 0,
          };

          formData.append("DocExcel", this.fileupload);
          formData.append("DataSearch", JSON.stringify(searchID));

          localStorage.setItem("DataSearch", JSON.stringify(searchID));

          this.http
            .post(
              environment.apibackdavid + "/api/Search/docid",
              formData,
              this.httpOptions
            )
            .subscribe((data) => {
              // console.log(data);
              // this.recibido = data;
              this.result.emit(data);
              this.showresult.emit(true);
              this.cleanForm();
            });
          break;
      }
    }
  }

  loadInicialiceForm() {
    return {
      hitsFrom: 0,
      hitsTo: 100,
      filterRegion: this.angForm.controls["filterRegion2"].value,
      filterPep: this.angForm.controls["CategoryPep2"].value,
      filterSic: this.angForm.controls["SpecialCategory2"].value,
      porcentaje: this.Percentage2,
      DateLocal: this.datepipe.transform(Date.now(), "MM-dd-yyyy hh:mm"),
      searchType:
        typeof this.angForm.controls["DefineSearch2"].value === "undefined"
          ? 1
          : this.angForm.controls["DefineSearch2"].value,
      name: "",
      dateOfBirth: "",
    };
  }

  LoadDataCombo(dataCountry, dataCategory, dataSpecialCategory) {
    debugger;
    const dataSerarch = this.loadInicialiceForm();
    if (dataSerarch.filterRegion !== "") {
      const data1 = dataCountry.filter((it) => {
        return it.value === dataSerarch.filterRegion;
      });
      dataSerarch.filterRegion = data1[0]["description"];
    } else {
      dataSerarch.filterRegion = "None";
    }

    if (dataSerarch.filterPep !== "") {
      const data2 = dataCategory.filter((it) => {
        return it.value === dataSerarch.filterPep;
      });
      dataSerarch.filterPep = data2[0]["description"];
    } else {
      dataSerarch.filterPep = "None";
    }

    if (dataSerarch.filterSic !== "") {
      const data3 = dataSpecialCategory.filter((it) => {
        return it.value === dataSerarch.filterSic;
      });
      dataSerarch.filterSic = data3[0]["description"];
    } else {
      dataSerarch.filterSic = "None";
    }

    localStorage.setItem("DataSearch", JSON.stringify(dataSerarch));
  }

  recibir($event) {
    if ($event === "Ning�n archivo seleccionado") {
      this.NombreArchivo = $event;
    } else {
      this.ArchivoRecibido = $event;
      this.NombreArchivo = this.ArchivoRecibido.name;
      this.fileupload = this.ArchivoRecibido;
    }
  }
  loadFilter() {
    this.servicefilter.TypeSearch(4).subscribe((result) => {
      this.country2 = result;
      localStorage.setItem("country", JSON.stringify(this.country2));
    });
    this.servicefilter.TypeSearch(3).subscribe((result) => {
      this.category2 = result;
      localStorage.setItem("category", JSON.stringify(this.category2));
    });
    this.servicefilter.TypeSearch(7).subscribe((result) => {
      this.specialCategory2 = result;
      localStorage.setItem(
        "specialCategory",
        JSON.stringify(this.specialCategory2)
      );
    });
  }

  validationForm() {
    this.angForm = this.fb.group({
      ShowSearchData2: [1],
      idTypeKey: [""],
      DefineSearch2: ["2"],
      Percentage2: [0],
      filterRegion2: [""],
      CategoryPep2: [""],
      SpecialCategory2: [""],
    });
  }
}
