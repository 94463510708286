import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/core/authentication/user';
import { AuthenticationService } from 'src/app/core/authentication/Authentication.service';
import { Language } from 'src/app/core/_helpers/Language';
import { SettingsAllUserComponent } from '../settings-all-user/settings-all-user.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { SearchService } from 'src/app/core/Search/search.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})


export class UsersComponent implements OnInit {

  constructor(private auth: AuthenticationService, private languaje: Language, private search: SearchService,
    private afAuth: AngularFireAuth) { }

  action: Number = 1;
  ListUserComp: User[];
  countUser;
  LengUser;
  @ViewChild(SettingsAllUserComponent, { static: false }) childAllUser !: SettingsAllUserComponent;

  @Input() public lenguaje: string;

  ngOnInit() {
    this.LengUser = this.lenguaje;
    this.languaje.IniLenguaje(this.lenguaje);

    if (typeof this.childAllUser !== 'undefined') {
      this.countUser = this.childAllUser.CountUserData;
    } else {
      this.countUser = 0;
    }
  }

  async loadListUserSon(uid) {

    return await this.auth.GetUserSon(uid).then(result => {
      this.ListUserComp = result;

    });
  }

  addUser() {
    this.action = 2;
    // console.log(this.action);
  }

  resultListUserComp($event) {
    this.ListUserComp = $event;
    console.log($event);
  }

  resultComp($event) {
    this.action = $event;
  }

  resultCount($event) {
    this.countUser = $event;
  }
}

