import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../core/authentication/Authentication.service';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../../../../environments/environment';

// var provider = new firebase.auth.GoogleAuthProvider();
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	Email: string;
	Password: string;
	resultData;
	public activeLang = 'es';

	constructor(
		private router: Router,
		private auth: AuthenticationService,
		private _snackBar: MatSnackBar,
		private translate: TranslateService,
		private http: HttpClient,
		public afAuth: AngularFireAuth
	) {
		this.translate.setDefaultLang(this.activeLang);
	}

	ngOnInit() {}

	onSubmitLogin() {
		debugger;
		localStorage.clear();
		this.auth
			.loginEmail(this.Email, this.Password)
			.then((res) => {
				this.auth
					.loginserver(localStorage.getItem('uid'))
					.subscribe((result) => {
						debugger;
						localStorage.setItem('language', this.activeLang);
						localStorage.setItem('TokenServe', result['token']);
						this.LoadPackUser();
					});
			})
			.catch((err) => {
				debugger;
				switch (err.code) {
					case 'auth/argument-error': {
						this._snackBar.open('Email y contraseña son requeridos', 'Cerrar', {
							duration: 3000,
						});
						break;
					}
					case 'auth/wrong-password': {
						this._snackBar.open('Email o contraseña es incorrecta', 'Cerrar', {
							duration: 3000,
						});
						break;
					}
					case 'auth/user-not-found': {
						this._snackBar.open('Email o contraseña es incorrecta', 'Cerrar', {
							duration: 3000,
						});
						break;
					}
					default: {
						this._snackBar.open('Error: ' + err.message, 'Cerrar', {
							duration: 3000,
						});
						break;
					}
				}
			});
	}

	cambiarLenguaje(event: any) {
		this.activeLang = event.target.value;
		localStorage.setItem('language', this.activeLang);
		this.translate.use(event.target.value);
	}

	onClickGoogleLogin() {
		this.auth
			.loginGoogle()
			.then((res) => {
				this.router.navigate(['/afterlogin']);
			})
			.catch((err) => console.log(err.message));
	}

	LoadPackUser() {
		debugger;
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
				responseType: 'text',
			}),
		};
		const guid = this.afAuth.auth.currentUser.uid;	
		this.http
			.get(environment.apiback + '/getaccount?id=' + guid, httpOptions)
			.subscribe((y) => {
				debugger;
				const w = JSON.stringify(y);
				const oj = JSON.parse(w);

				if(oj.estadoCuenta)
				{
					const idMembresia = oj.idMembresiaStripe;
					const url1 = environment.apiphp + '?idsub=' + idMembresia;
					this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
						const arre = JSON.parse(x);
						const idProducto = arre.items.data[0].plan.product;					
						localStorage.setItem('plan', idProducto);
						this._snackBar.open('Usuario logeado correctamente.', 'Cerrar', {
							duration: 3000,
						});
						this.router.navigate(['/afterlogin']);					
					});  
				}else
				{					
					this._snackBar.open('Usuario bloqueado.', 'Cerrar', {
						duration: 3000,
					});
					this.Logout();			
				}

				
			});
	}

	Logout() {
	
		this.auth.logout().then(
			(user) => {
				localStorage.clear();
				this.router.navigate(['/login']);
			},
			(error) => {				
				localStorage.clear();
				this.router.navigate(['/login']);
			}
		);
	}


}
