import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SearchService } from '../../../core/Search/search.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { RecurringSearchService } from '../../../core/_service/recurring-search.service';
import { Datostabla } from '../../../shared/datos-tabla';
import { Router } from '@angular/router';
@Component({
	selector: 'app-recurringsearchreport',
	templateUrl: './recurringsearchreport.component.html',
	styleUrls: ['./recurringsearchreport.component.scss'],
})
export class RecurringsearchreportComponent implements OnInit, AfterViewInit {
	mostrarSpinner: boolean = false;
	public ComponentView = 0;
	showPDF2: boolean = false;
	Email;
	pid;
	public urlPdf2;
	data: ResultsCSView[] = [];
	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;
	exampleDatabase: HttpDatabase | null;
	@ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	Data: ResultsCSView[] = [];
	Columns: string[] = ['a', 'b', 'c', 'd', 'e'];
	paginas: number[] = [5, 10, 25, 50, 100];
	parametro: string = '';

	showDetailResult = true;
	DataResult: any;
	noResult = false;

	constructor(
		private translate: TranslateService,
		private service: RecurringSearchService,
		private ser: SearchService,
		private sanitizer: DomSanitizer,
		private _snackBar: MatSnackBar,
		private _httpClient: HttpClient,
		private router: Router
	) {}

	ShowPaginatDetail($event) {}

	bandShowNum: number = 1;
	showBackbtn = false;
	showDetailModal(data) {
		debugger;
		let json = JSON.stringify(this.Data);

		localStorage.setItem('pagina-index', this.paginator.pageIndex.toString());

		if (data.results != '[]') {
			let obj = JSON.parse(data.searchmodeljson);

			let showTypeSearch = '3';
			if (obj.surname === undefined && obj.entityName !== undefined)
				showTypeSearch = '2';
			else if (obj.surname !== undefined && obj.entityName === undefined)
				showTypeSearch = '1';

			localStorage.setItem('showTypeSearch', showTypeSearch);
			localStorage.setItem('DataSearch', JSON.stringify(obj));
			this.showDetailResult = false;
			this.DataResult = JSON.parse(data.results);
			localStorage.setItem('massive', 'massive');
		} else {
			let dataSearch = JSON.parse(data.searchmodeljson);
			dataSearch.filterRegion = this.isNullOrempty(dataSearch.filterRegion);
			dataSearch.filterPep = this.isNullOrempty(dataSearch.filterPep);
			dataSearch.filterSic = this.isNullOrempty(dataSearch.filterSic);

			localStorage.setItem('DataSearch', data.searchmodeljson);
			this.noResult = true;
			this.showDetailResult = false;
			this.bandShowNum =
				data.typeofsearch == 3 || data.typeofsearch == 1
					? 1
					: data.typeofsearch == 0
					? 2
					: data.typeofsearch == 2
					? 3
					: 0;
		}
	}

	private isNullOrempty(valor) {
		return valor === undefined || valor === null || valor === ''
			? 'None'
			: valor;
	}

	public tipoDeBusqueda(value): string {
		switch (value) {
			case 0:
				return this.translate.instant('Precise.texto');
			case 1:
				return this.translate.instant('Near.texto');
			case 2:
				return this.translate.instant('Broad.texto');
			default:
				return '';
		}
	}

	ngOnInit() {
		this.translate.use(localStorage.getItem('language'));
	}

	ngAfterViewInit() {
		this.mostrarSpinner = true;
		this.Filtrar();
		this.ActualizarIndexPage();
	}

	changeLeng($event) {
		this.translate.use($event);
		localStorage.setItem('language', $event);
	}

	showHeptcenter($event) {
		this.ComponentView = $event;
	}

	resultComp($event) {
		this.router.navigateByUrl('afterlogin');
	}

	cleanComp($event) {
		location.reload();
	}

	async ActualizarIndexPage() {
		let index = localStorage.getItem('pagina-index');
		if (index !== null) {
			this.mostrarSpinner = true;
			await new Promise((f) => setTimeout(f, 500));
			this.paginator.pageIndex = Number(localStorage.getItem('pagina-index'));
			localStorage.removeItem('pagina-index');
			this.mostrarSpinner = false;
		}
	}

	private Filtrar() {
		this.exampleDatabase = new HttpDatabase(this.service);
		this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

		merge(this.sort.sortChange, this.paginator.page)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					return this.exampleDatabase!.Filtrar(
						this.sort.active,
						this.sort.direction,
						this.paginator.pageSize,
						this.paginator.pageIndex + 1
					);
				}),
				map((data: any) => {
					this.mostrarSpinner = false;
					if (data != null) {
						this.isLoadingResults = false;
						this.isRateLimitReached = false;
						this.resultsLength = data.data.rowCount;
						let tabla = new ValueTabla();
						tabla.items = <ResultsCSView[]>data.data.result;
						tabla.total_count = data.data.rowCount;

						let index = this.paginator._displayedPageSizeOptions.findIndex(
							(x) => x == data.data.rowCount
						);
						if (index < 0)
							this.paginator._displayedPageSizeOptions.push(data.data.rowCount);

						return tabla;
					} else {
						let tabla: ValueTabla;
						return tabla;
					}
				}),
				catchError((msj) => {
					this.mostrarSpinner = false;
					console.log(msj);
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			)
			.subscribe((data) => (this.Data = (<ValueTabla>data).items));
	}

	public showDetail2(pid: string, searchmodeljson: any) {
		this.showPDF2 = false;
		this.ser
			.ShowPDF3(pid, this.paginator.pageSize, this.paginator.pageIndex + 1)
			.subscribe((resp) => {
				this.urlPdf2 = this.sanitizer.bypassSecurityTrustResourceUrl(
					window.URL.createObjectURL(
						new Blob([resp], { type: 'application/pdf' })
					)
				);

				this.showPDF2 = true;
			});
	}

	public showDetail() {
		this.showPDF2 = false;
		this.ser
			.ShowPDF3(0, this.paginator.pageSize, this.paginator.pageIndex + 1)
			.subscribe((resp) => {
				this.urlPdf2 = this.sanitizer.bypassSecurityTrustResourceUrl(
					window.URL.createObjectURL(
						new Blob([resp], { type: 'application/pdf' })
					)
				);

				this.showPDF2 = true;
			});
	}

	public showEmail2(pid: string, searchmodeljson: any): void {
		this.pid = pid;
		localStorage.setItem('DataSearch', searchmodeljson);
	}

	public SendEmail2(): void {
		this.ser
			.SendEmail2(
				this.paginator.pageSize,
				this.paginator.pageIndex + 1,
				this.Email
			)
			.subscribe(
				(r) => {
					if (r.send) {
						this._snackBar.open(JSON.stringify(r.message), 'Cerrar', {
							duration: 3000,
						});
					} else {
						this._snackBar.open(JSON.stringify(r.message), 'Cerrar', {
							duration: 3000,
						});
					}
				},
				(error) => {
					this._snackBar.open(JSON.stringify(error), 'Cerrar', {
						duration: 3000,
					});
					console.log(JSON.stringify(error));
				}
			);
	}
}

export interface ResultsCSView {
	name: string;
	coincidence: boolean;
	change: boolean;
	typeofsearch: number;
	searchmodeljson: string;
}

export class ValueTabla {
	items: ResultsCSView[];
	total_count: number;
}

export interface modelo {
	nombre: string;
	id: string;
}

export class HttpDatabase {
	constructor(private serv: RecurringSearchService) {}
	public Filtrar(
		sort: string,
		order: string,
		PageSize: number,
		page: number
	): Observable<Datostabla> {
		return this.serv.BusquedaPorUsuario2(sort, order, PageSize, page);
	}
}

export interface ListResult {
	pid: number;
	riskIcons: {
		riskIcon: string;
	};
	primaryName: string;
	countryCode: string;
	porcentajescore: number;
}
