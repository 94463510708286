import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/Authentication.service';
import { User } from 'src/app/core/authentication/user';
import { filter } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import { Helpers } from 'src/app/core/_helpers/Helpers';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-settings-all-user',
  templateUrl: './settings-all-user.component.html',
  styleUrls: ['./settings-all-user.component.scss']
})
export class SettingsAllUserComponent implements OnInit {

  constructor(private auth: AuthenticationService, private afAuth: AngularFireAuth) { }

  Helpers: Helpers = new Helpers();
  @Input() ListUser: User[];
  @Output() CountUserData = new EventEmitter<number>();

  user: User;
  ngOnInit() {
    // const id = localStorage.getItem('uid');
    const id = this.afAuth.auth.currentUser.uid;
    this.loadListUserSon(id);
  }

  async loadListUserSon(uid) {
    // console.log(uid);
    await this.auth.GetUserSon(uid).then(result => {
      this.ListUser = this.Helpers.ConvertDetails(result);
      this.CountUserData.emit(this.ListUser.length);
      // console.log(this.ListUser);
    });
  }

}
