import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StripeScriptTag, StripeSource } from 'stripe-angular';
import * as firebase from 'firebase/app';
import { StripeToken } from 'stripe-angular';
import { environment } from '../../../../../environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { getLocaleDateTimeFormat } from '@angular/common';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
// import { AuthenticationService } from '../../../../core/authentication/Authentication.service';
import { codigosstripe } from '../../../../shared/urls/codigosstripe';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogData } from 'src/app/modules/account/upgrade/upgrade.component';
import { DialogOverviewComponent } from 'src/app/shared/components/modal/dialog-overview/dialog-overview.component';
import { AuthenticationService } from 'src/app/core/authentication/Authentication.service';
import { AddonsService } from 'src/app/shared/packs/addons.service';
import { CountryService } from 'src/app/core/country/country.service';
import { TranslateService } from '@ngx-translate/core';
import { Country } from 'src/app/core/country/country.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { url } from 'src/app/shared/urls/url';
@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
	lang = localStorage.getItem('language');
	public show = false;
	public email;
	public phone;
	public company;
	public pass;
	public plan;
	pack: AddonsService;
	arrepack: Array<AddonsService>;
	public planprecio;
	public uid;
	public nombreplan;
	public precio;
	public check = true;
	public customer;
	public mensual = true;
	private publishableKey: string = this.cod.apistripe;
	public direccion;
	public telefono;
	public pais;
	public empresa;
	formCard: FormGroup;
	countryList = new Array<Country>();
	extraData = {
		name: '',
		address_line1: '',
		address_line2: '',
	};
	isdisable = false;
	planCod = codigosstripe;

	constructor(
		public dialog: MatDialog,
		private auth: AuthenticationService,
		private cod: codigosstripe,
		private http: HttpClient,
		public stripeScriptTag: StripeScriptTag,
		private rutaActiva: ActivatedRoute,
		private router: Router,
		private countryService: CountryService,
		private translate: TranslateService
	) {
		this.stripeScriptTag.setPublishableKey(this.publishableKey);
		this.email = this.rutaActiva.snapshot.params.email;
		this.plan = this.rutaActiva.snapshot.params.plan;
		this.planprecio = this.rutaActiva.snapshot.params.planprecio;
		this.uid = this.rutaActiva.snapshot.params.uid;
		this.extraData.name = this.rutaActiva.snapshot.params.name;
		this.pais = this.rutaActiva.snapshot.params.pais;
		this.direccion = this.rutaActiva.snapshot.params.direccion;
		this.empresa = this.rutaActiva.snapshot.params.empresa;
		this.telefono = this.rutaActiva.snapshot.params.telefono;
		const url1 = environment.apiphp2 + '?consultarplanporproducto=' + this.plan;
		localStorage.setItem('language', 'es');
		this.translate.setDefaultLang('es');
		this.http.get(url1).subscribe(
			(x: any) => {
				// var cadena = x.replace('Missing required param: source.', '');
				var arre = x.Planes; // JSON.parse(cadena);
				this.nombreplan = arre.nickname;
				if (this.plan === cod.packs) {
				} else {
					this.precio = arre.amount / 100;
				}
			},
			(error) => console.log(error)
		);
		this.showCountry();
	}

	ngOnInit() {
		this.createForm();
		if (this.plan === this.cod.packs) {
			this.mensual = false;
		}
		this.arrepack = new Array<AddonsService>();
		this.http.get(environment.apiback + '/getpack').subscribe((y) => {
			var w = JSON.stringify(y);
			var oj = JSON.parse(w);
			oj.forEach((x) => {
				var metadata = JSON.parse(x.metadataPack);
				var nombre = x.name;
				var precio = metadata.precio;
				var cupos = metadata.verificaciones;
				this.pack = new AddonsService(nombre, precio, 0, cupos, 0, 0, 0);
				this.arrepack.push(this.pack);
			});
			var pack;

			if (this.planprecio === this.cod.planpack1) {
				pack = this.arrepack.find(
					(x) => x.nombre.toLowerCase() === this.planprecio
				);
				this.precio = parseFloat(pack.precio);
			} else if (this.planprecio === this.cod.planpack2) {
				pack = this.arrepack.find(
					(x) => x.nombre.toLowerCase() === this.planprecio.replace('ó', 'o')
				);
				this.precio = parseFloat(pack.precio);
			}
		});

		if (this.plan === this.cod.demo) {
			this.check = false;
		} else {
			this.check = true;
		}
	}

	createForm() {
		this.formCard = new FormGroup({
			name: new FormControl('', [Validators.required]),
			country: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.required]),
			direction: new FormControl('', [Validators.required]),
			company: new FormControl(''),
			tel: new FormControl(''),
		});
	}

	volver() {
		this.auth.eliminar().then((result) => {
			const data: any = {};

			firebase
				.database()
				.ref('users/' + this.uid)
				.set(data);
			const httpOptions = {
				headers: new HttpHeaders({
					Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
					responseType: 'arraybuffer',
				}),
			};
			const urlLocal =
				environment.apibackdavid + '/api/user/deleteuser/' + this.uid;
			var x = this.http.get(urlLocal, httpOptions);
			if (x != null) {
				this.router.navigate(['/signup', this.plan, this.planprecio]);
			}
		});
	}

	onStripeInvalid(error: Error) {
		console.log('Validation Error', error);
	}

	setStripeToken(token: StripeToken) {
		try {
			const url1 =
				environment.apiphp +
				'?source=' +
				token.id +
				'&email=' +
				this.email +
				'&description=' +
				this.extraData.name +
				'&miplan=' +
				this.plan +
				'&company=' +
				this.empresa +
				'&phone=' +
				this.telefono +
				'&uid=' +
				this.uid +
				'&direccion=' +
				this.direccion +
				'&pais=' +
				this.pais;
			this.http.get(url1, { responseType: 'text' }).subscribe(
				(xy) => {
					var j = JSON.parse(xy.toString());
					const numDate1: number = j.current_period_start;
					const numDate2: number = j.current_period_end;

					const date1 = new Date(numDate1 * 1000);
					const date2 = new Date(numDate2 * 1000);

					if (j.cliente !== undefined) {
						const data = {
							idcustomer: j.cliente,
							idmembresiastripe: j.membresia,
							googleuid: this.uid,
							ExpirationDate: date2,
							StartDate: date1,
						};
						this.customer = j.cliente;
						const url2 = environment.apiback + '/register';
						this.http.post(url2, data).subscribe(
							(y) => {
								var w = JSON.stringify(y);
								var oj = JSON.parse(w);
								var cant = 0;
								if (this.plan === this.cod.essential) {
									cant = 5;
								} else if (this.plan === this.cod.standard) {
									cant = 15;
								} else if (this.plan === this.cod.pro) {
									cant = 50;
								} else if (this.plan === this.cod.ultra) {
									cant = 100;
								}
								var dataX;
								if (this.plan !== this.cod.packs) {
									dataX = {
										IDCuenta: oj.cuenta.idCuenta,
										Verificaciones: cant,
									};
									const url3 = environment.apiback + '/registerPackCuenta';
									this.http.post(url3, dataX).subscribe(
										(y) => {
											var w = JSON.stringify(y);
											var oj = JSON.parse(w);
											console.log(
												'/agradecimiento',
												'Gracias por adquirir el Plan',
												this.nombreplan,
												'1'
											);
											this.router.navigate([
												'/agradecimiento',
												'Gracias por adquirir el Plan',
												this.nombreplan,
											]);
										},
										(error) => console.log(error)
									);
								} else {
									var pack;
									var precio;
									var cantprecio = 0;
									var nombreplanprecio = '';
									if (this.planprecio === this.cod.planpack1) {
										pack = this.arrepack.find(
											(x) => x.nombre === this.planprecio
										);
										precio = parseFloat(pack.precio) * 100;
										cantprecio = pack.cupos;
									} else if (this.planprecio === this.cod.planpack2) {
										pack = this.arrepack.find(
											(x) => x.nombre === this.planprecio
										);
										precio = parseFloat(pack.precio) * 100;
										cantprecio = pack.cupos;
									}
									dataX = {
										IDCuenta: oj.cuenta.idCuenta,
										Verificaciones: cantprecio,
									};
									const url3 = environment.apiback + '/registerPackCuenta';
									const url1 =
										environment.apiphp2 +
										'?comprarpack=true&nombre=' +
										pack.nombre +
										'&precio=' +
										precio +
										'&customer=' +
										this.customer;
									this.http.get(url1, { responseType: 'text' }).subscribe(
										(x) => {
											this.http.post(url3, dataX).subscribe(
												(y) => {
													var w = JSON.stringify(y);
													var oj = JSON.parse(w);
													console.log(
														'/agradecimiento',
														'Gracias por adquirir el Plan',
														pack.nombre,
														'2'
													);
													this.router.navigate([
														'/agradecimiento',
														'Gracias por adquirir el Plan',
														pack.nombre,
													]);
												},
												(error) => console.log(error)
											);
										},
										(error) => console.log(error)
									);
								}
							},
							(error) => console.log(error)
						);
					} else {
						this.auth.eliminar().then((result) => {
							const data: any = {};
							firebase
								.database()
								.ref('users/' + this.uid)
								.set(data);
							this.openDialog(j.error);
						});
					}
				},
				(error) => console.log(error)
			);
		} catch (e) {
			console.log('error=' + e.message);
		}
	}

	registrarDemo() {
		try {
			const url1 =
				environment.apiphp +
				'?email=' +
				this.email +
				'&description=' +
				this.extraData.name +
				'&miplan=' +
				this.plan +
				'&uid=' +
				this.uid;
			this.http.get(url1, { responseType: 'text' }).subscribe((xy) => {
				var j = JSON.parse(xy.toString());

				const numDate1: number = j.current_period_start;
				const numDate2: number = j.current_period_end;

				const date1 = new Date(numDate1 * 1000);
				const date2 = new Date(numDate2 * 1000);

				if (j.cliente != undefined) {
					const data = {
						idcustomer: j.cliente,
						idmembresiastripe: j.membresia,
						googleuid: this.uid,
						ExpirationDate: date2,
						StartDate: date1,
					};
					const url2 = environment.apiback + '/register';
					this.http.post(url2, data).subscribe(
						(y) => {
							var w = JSON.stringify(y);
							var oj = JSON.parse(w);
							var cant = 5;
							const dataX = {
								IDCuenta: oj.cuenta.idCuenta,
								Verificaciones: cant,
							};
							const url3 = environment.apiback + '/registerPackCuenta';
							this.http.post(url3, dataX).subscribe(
								(y) => {
									var w = JSON.stringify(y);
									var oj = JSON.parse(w);
									this.router.navigate([
										'/agradecimiento',
										'Gracias por adquirir el Plan',
										this.nombreplan,
									]);
								},
								(error) => console.log(error)
							);
						},
						(error) => console.log(error)
					);
				} else {
					this.auth.eliminar().then((result) => {
						const data: any = {};
						firebase
							.database()
							.ref('users/' + this.uid)
							.set(data);
						this.openDialog(j.error);
					});
				}
			});
		} catch (e) {
			console.log('error=' + e.message);
		}
	}

	setStripeSource(source: StripeSource) {
		console.log('Stripe source', source);
	}

	onStripeError(error: Error) {
		console.error('Stripe error', error);
	}

	openDialog(DataSearch): void {
		const dialogRef = this.dialog.open(DialogOverviewComponent, {
			width: '500px',
			data: DataSearch,
		});
		dialogRef.afterClosed().subscribe((result) => {
			console.log('The dialog was closed');
			// this.animal = result;
		});
	}

	showCountry() {
		this.countryList = this.countryService.getCountry();
	}

	/* LoadPackUser() {
    const guid = this.afAuth.auth.currentUser.uid;
     // const guid = localStorage.getItem('uid');
    this.http.get(this.user.apiback + '/getaccount?id=' + guid).subscribe(y => {
      const w = JSON.stringify(y);
      const oj = JSON.parse(w);
      const idMembresia = oj.idMembresiaStripe;
      const idCustomer = oj.idCustomer;
      const idCuenta = oj.idCuenta;
      const url1 = this.user.apiphp + '?idsub=' + idMembresia;
      this.http.get(url1, { responseType: 'text' }).subscribe(x => {
        const arre = JSON.parse(x);
        const idProducto = arre.items.data[0].plan.product;
        // console.log(this.idProducto);
        localStorage.setItem('plan', idProducto);

        const datePeriodStarBack = new Date(oj.startDate);
        const datePeriodEndBack = new Date(oj.expirationDate);
        const dataend = arre.current_period_end;
        const datst = arre.current_period_start;
        // const das = x['current_period_end'];
        const datePeriodStart = new Date(datst * 1000);
        const datePeriodEnd = new Date(dataend * 1000);
        if ((datePeriodStarBack < datePeriodStart) && arre.status === 'active') {

          const dataCout = {
            googleUid: guid,
            newDateStar: datePeriodStart,
            newDateEnd: datePeriodEnd
          };

          if (oj.first) {
            this.http.post(this.user.apiback + '/updatefirst', dataCout).subscribe(res => {

              if (res === 'Updated') {
                this._snackBar.open('Usuario logeado correctamente.', 'Cerrar', { duration: 3000 });
                this.router.navigate(['/afterlogin']);
              }else{
                this._snackBar.open('El usuario no posee cuenta.', 'Cerrar', { duration: 3000 });
              }
            });
          } else {
            this.http.post(this.user.apiback + '/UpdatCuentaPack', dataCout).subscribe(res => {

              if (res['status']) {
                this._snackBar.open('Usuario logeado correctamente.', 'Cerrar', { duration: 3000 });
                this.router.navigate(['/afterlogin']);
              }else{
                this._snackBar.open('Ha ocurrido un error al actualizar la cuenta.', 'Cerrar', { duration: 3000 });
                this.router.navigate(['/afterlogin']);
              }
            });
          }

        }else {
          this._snackBar.open('Usuario logeado correctamente.', 'Cerrar', { duration: 3000 });
          this.router.navigate(['/afterlogin']);
        }
      });
    });
  }
 */
}
