import { Component, OnInit, Input } from '@angular/core';
import { DetailsPerson } from 'src/app/core/Search/Search';
import { Helpers } from 'src/app/core/_helpers/Helpers';

@Component({
  selector: 'app-result-detail-person',
  templateUrl: './result-detail-person.component.html',
  styleUrls: ['./result-detail-person.component.scss']
})
export class ResultDetailPersonComponent implements OnInit {

  DateOfBirth;
  Country;
  personNameDetails;
  identificationDetails;
  imageDetails;
  watchlistContent;
  roleDetails;
  associates;
  Helpers: Helpers = new Helpers();
  curDate;
  sourceDetails;
  note;

  @Input() DetailsPersonView: DetailsPerson;
  constructor() { }

  ngOnInit() {
    this.LoadDetail();
    this.curDate = new Date();
  }

  LoadDetail() {

    this.Country = this.DetailsPersonView.countryDetails.countryValues;
    this.personNameDetails = this.Helpers.ConvertDetails(this.DetailsPersonView.personNameDetails.personNames);
    this.DateOfBirth = this.Helpers.ConvertDetails(this.DetailsPersonView.dateDetails.dates);
    this.identificationDetails = this.Helpers.ConvertDetails(this.DetailsPersonView.identificationDetails.identification);
    this.imageDetails = this.Helpers.ConvertDetails(this.DetailsPersonView.imageDetails.imageUrl);
    this.watchlistContent = this.Helpers.ConvertDetails(this.DetailsPersonView.watchlistContent.descriptions.description);
    this.roleDetails = this.Helpers.ConvertDetails(this.DetailsPersonView.watchlistContent.roleDetails.roles);
    this.associates = this.Helpers.ConvertDetails(this.DetailsPersonView.associates.associate);
    this.sourceDetails = this.Helpers.ConvertDetails(this.DetailsPersonView.watchlistContent.sourceDetails.sources);
    this.note = this.DetailsPersonView.watchlistContent.profileNotes.valueOf().replace('\\\n', '\n');
  }

}
