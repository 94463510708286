import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/Authentication.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { User, Roles } from 'src/app/core/authentication/user';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/core/_helpers/Language';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  @Output() public resultChange = new EventEmitter<number>();
  @Output() public resultListUser = new EventEmitter<User[]>();
  @Input() public lenguaje: string;

  Password: string;
  UserRol: string;
  PasswordConfirm: string;
  Email: string;
  DisplayName: string;
  Error: string;
  DisplayNameText;
  user: User = new User();
  sendSub = false;

  constructor(private router: Router,
    private auth: AuthenticationService,
    public flashMessage: FlashMessagesService,
    private languaje: Language,
    public afAuth: AngularFireAuth
  ) { }

  uid: string;

  ngOnInit() {
    // this.uid = localStorage.getItem('uid');
    this.uid = this.afAuth.auth.currentUser.uid;
    this.translateIni();
  }


  SignupUse() {
    this.sendSub = true;

    this.user.displayName = this.DisplayNameText;
    this.user.email = this.Email;


    this.user.displayName = this.DisplayNameText;
    this.user.email = this.Email.toLowerCase();
    this.user.parentUser = this.uid;
    this.user.displayFirstName = this.DisplayNameText;
    this.user.displayLastName = this.DisplayNameText;

    if (this.UserRol === '1') {
      this.user.roles = {
        admin: true,
        user: false,
      };
    } else if (this.UserRol === '2') {
      this.user.roles = {
        admin: false,
        user: true,
      };
    }

    // console.log(this.user);
    // console.log(this.UserRol);
    this.auth.registerUser(this.user, this.Password).then(() => {
      this.flashMessage.show('successfully created user', { cssClass: 'alert-success', timeout: 4000 });
      this.resultChange.emit(1);
      this.user.uid = localStorage.getItem('userNew');
      this.auth.RecordUserData(this.user);

      // this.user.uid = this.uid;
      this.auth.registerUserBack(this.user, this.Password).subscribe(
        (res: any) => {
          console.log(res);
         // if (res.succeeded) {
            this.loadListUserSon(this.uid);
            console.log({ html: 'Se a agregado correctamente el nivel de educación' });
            this.resultChange.emit(1);

          /*} else {
            res.errors.forEach(element => {
              switch (element.code) {
                case 'DuplicateUserName':
                  console.log({ html: 'Username is already taken.' + 'Registration failed' });
                  break;

                default:
                  console.log({ html: element.description + 'Registration failed' });
                  break;
              }
            });

          }*/
        },
        err => {
          console.log(err);
        }
      );


    }).catch((err) => {
      this.flashMessage.show(err, { cssClass: 'alert-danger', timeout: 4000 });
    });
  }

  back() {
    this.resultChange.emit(1);
  }

  loadListUserSon(uid) {
    this.auth.GetUserSon(uid).then(result => {
      this.sendSub = true;
      this.resultListUser.emit(result);
    });
  }

  translateIni() {
    const lan = localStorage.getItem('language');
    this.languaje.IniLenguaje(lan);
  }

  onChange(rol) {
    this.UserRol = rol;
  }
}
