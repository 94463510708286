import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
  QueryList,
  Input,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  BsDatepickerConfig,
  BsDatepickerViewMode,
  BsLocaleService,
} from "ngx-bootstrap/datepicker";
import { Search } from "src/app/core/Search/Search";
import { MatSnackBar, MatTableDataSource } from "@angular/material";
import { SelectFiltreService } from "src/app/core/SelectFiltre/select-filtre.service";
import { SearchService } from "src/app/core/Search/search.service";
import { Plans } from "src/app/shared/urls/plans";
import { ListResult } from "../result-search/result-search.component";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { DialogOverviewComponent } from "src/app/shared/components/modal/dialog-overview/dialog-overview.component";
import { codigosstripe } from "src/app/shared/urls/codigosstripe";
import { AngularFireAuth } from "@angular/fire/auth";
import { Global } from "src/app/core/_service/global";
import { SubMenuComponent } from "src/app/shared/components/sub-menu/sub-menu.component";

@Component({
  selector: "app-search-person",
  templateUrl: "./search-person.component.html",
  styleUrls: ["./search-person.component.scss"],
})
export class SearchPersonComponent implements OnInit {
  @Output() public resultView = new EventEmitter<number>();
  @Output() public bandShowNoResult = new EventEmitter<number>();
  @ViewChild("radioDataSearch1", { static: false }) radio1: ElementRef;
  angForm: FormGroup;
  ShowSearchData = 1;
  bsValue: Date = new Date(2019);
  minMode: BsDatepickerViewMode = "year";
  listSearchData;
  result = 1;
  clean = 0;
  locale = "en";
  Percentage = 80;
  DefineSearch = 2;
  NavIDentif;
  loandingbusqued = true;
  activeBtn = false;
  errorSubmit = false;
  country;
  category;
  specialCategory;
  DefineSearchTypeData;
  search = new Search();
  bsConfig2: Partial<BsDatepickerConfig>;
  plan;
  planSt;
  constructor(
    public localeService: BsLocaleService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private servicefilter: SelectFiltreService,
    private serviceSearch: SearchService,
    private typeplan: Plans,
    public dialog: MatDialog,
    private codigoStr: codigosstripe,
    private afAuth: AngularFireAuth,
    private VarGlobal: Global,
    private el: ElementRef
  ) {
    this.planSt = codigoStr;
  }

  ngOnInit() {
    //console.log(this.country);
    this.detectNav();
    this.localeService.use(this.locale);
    this.bsConfig2 = Object.assign(
      {},
      {
        minMode: this.minMode,
        dateInputFormat: "YYYY",
        containerClass: "theme-Red-wine",
      }
    );
    this.validationForm();
    this.loadFilter();
    this.loadInicialiceForm();
    // this.plan = this.typeplan.LoadPackUser();

    this.plan = localStorage.getItem("plan");
  }

  loadFilter() {
    this.servicefilter.TypeSearch(4).subscribe((result) => {
      this.country = result;
      localStorage.setItem("country", JSON.stringify(this.country));
    });
    this.servicefilter.TypeSearch(3).subscribe((result) => {
      this.category = result;
      localStorage.setItem("category", JSON.stringify(this.category));
    });
    this.servicefilter.TypeSearch(7).subscribe((result) => {
      this.specialCategory = result;
      localStorage.setItem(
        "specialCategory",
        JSON.stringify(this.specialCategory)
      );
    });
  }

  validationForm() {
    this.angForm = this.fb.group({
      FirstName: ["", Validators.required],
      SecondName: [""],
      Surname: [""],
      Entity: [""],
      Birthdate: [""],
      Year: [""],
      DefineSearchType: ["1"],
      Region: [""],
      Category: [""],
      SpecialCategory: [""],
      filterRegion: [""],
      CategoryPep: [""],
      SearchPercentage: [80],
      searchData: [1],
    });
  }

  get FirstName() {
    return this.angForm.get("FirstName");
  }
  get SecondName() {
    return this.angForm.get("SecondName");
  }
  get Surname() {
    return this.angForm.get("Surname");
  }
  get Entity() {
    return this.angForm.get("Entity");
  }
  get Birthdate() {
    return this.angForm.get("Birthdate");
  }
  get Year() {
    return this.angForm.get("Year");
  }
  get DefineSearchType() {
    return this.angForm.get("DefineSearchType");
  }
  get filterRegion() {
    return this.angForm.get("filterRegion");
  }
  get Category() {
    return this.angForm.get("Category");
  }
  get SpecialCategory() {
    return this.angForm.get("SpecialCategory");
  }
  get CategoryPep() {
    return this.angForm.get("CategoryPep");
  }
  get SearchPercentage() {
    return this.angForm.get("SearchPercentage");
  }
  get searchData() {
    return this.angForm.get("searchData");
  }

  clickData(event) {
    if (event.value === 1) {
      this.ShowSearchData = 1;
      this.angForm.controls["FirstName"].value("");
    } else if (event.value === 2) {
      this.ShowSearchData = 2;
    }
    localStorage.setItem("showTypeSearch", this.searchData.toString());
  }

  loadInicialiceForm() {
    this.search = {
      FirstName: this.angForm.controls["FirstName"].value,
      SecondName: this.angForm.controls["SecondName"].value,
      Surname: this.angForm.controls["Surname"].value,
      Entity: this.angForm.controls["Entity"].value,
      Birthdate: this.angForm.controls["Birthdate"].value,
      Year: this.angForm.controls["Year"].value,
      DefineSearchType: this.angForm.controls["DefineSearchType"].value,
      filterRegion: this.angForm.controls["filterRegion"].value,
      CategoryPep: this.angForm.controls["CategoryPep"].value,
      SpecialCategory: this.angForm.controls["SpecialCategory"].value,
      SearchPercentage: this.angForm.controls["SearchPercentage"].value,
    };
  }

  onSubmitFormData() {
    localStorage.setItem("massive", "");
    const searType = this.angForm.controls["searchData"].value;
    if (
      this.angForm.controls["FirstName"].value === "" &&
      this.angForm.controls["SecondName"].value === "" &&
      this.angForm.controls["Surname"].value === "" &&
      searType === 1
    ) {
      this.errorSubmit = true;
    } else {
      this.loandingbusqued = false;
      this.activeBtn = true;
      // const id = this.afAuth.auth.currentUser.uid;

      const dataCountry = <any[]>JSON.parse(localStorage.getItem("country"));
      const dataCategory = <any[]>JSON.parse(localStorage.getItem("category"));
      const dataSpecialCategory = <any[]>(
        JSON.parse(localStorage.getItem("specialCategory"))
      );

      this.serviceSearch.Verifications(0).subscribe((res) => {
        this.VarGlobal.CoutValid = +res;
        if (res > 0) {
          this.loadInicialiceForm();
          localStorage.setItem("showTypeSearch", searType.toString());
          localStorage.setItem("countVer", res.toString());
          if (searType === 1) {
            this.serviceSearch.searchPerson(this.search).subscribe(
              (result) => {
                this.listSearchData = result.list;

                this.bandShowNoResult.emit(1);
                this.LoadDataCombo(
                  dataCountry,
                  dataCategory,
                  dataSpecialCategory
                );
                if (this.listSearchData.length !== 0) {
                  this.resultView.emit(2);
                  this.loandingbusqued = true;
                } else {
                  this.loandingbusqued = true;
                  this.resultView.emit(4);
                }
                this.activeBtn = false;
                // tslint:disable-next-line: no-unused-expression
              },
              (err) => {
                this.loandingbusqued = true;
                this.activeBtn = false;
                this._snackBar.open(err, "Cerrar", {
                  duration: 3000,
                });
              }
            );
          } else if (searType === 2) {
            this.serviceSearch.searchEntity(this.search).subscribe((result) => {
              this.LoadDataCombo(
                dataCountry,
                dataCategory,
                dataSpecialCategory
              );

              this.bandShowNoResult.emit(2);

              if (result.length !== 0) {
                this.listSearchData = result;

                // if (result[0]['codeError'] === 0) {
                this.loandingbusqued = true;
                this.resultView.emit(2);
                // } else {
                //   this.loandingbusqued = true;
                //   this.resultView.emit(4);
                // }
              } else {
                this.loandingbusqued = true;
                this.resultView.emit(4);
              }

              this.activeBtn = false;
            });
          }
        } else {
          this.loandingbusqued = true;
          this.activeBtn = false;
          this._snackBar.open("Usted no posee cupos disponibles", "Cerrar", {
            duration: 10000,
          });
        }
      });
    }
  }

  LoadDataCombo(dataCountry, dataCategory, dataSpecialCategory) {
    if (this.serviceSearch.dataSearchLocal.filterRegion !== "") {
      const data1 = dataCountry.filter((it) => {
        return it.value === this.serviceSearch.dataSearchLocal.filterRegion;
      });

      if (data1.length > 0) {
        if (typeof data1[0]["description"] !== "undefined") {
          this.serviceSearch.dataSearchLocal.filterRegion =
            data1[0]["description"];
        }
      }
    } else {
      this.serviceSearch.dataSearchLocal.filterRegion = "None";
    }

    if (this.serviceSearch.dataSearchLocal.filterPep !== "") {
      const data2 = dataCategory.filter((it) => {
        return it.value === this.serviceSearch.dataSearchLocal.filterPep;
      });
      if (data2.length > 0)
        this.serviceSearch.dataSearchLocal.filterPep = data2[0]["description"];
    } else {
      this.serviceSearch.dataSearchLocal.filterPep = "None";
    }

    if (this.serviceSearch.dataSearchLocal.filterSic !== "") {
      const data3 = dataSpecialCategory.filter((it) => {
        return it.value === this.serviceSearch.dataSearchLocal.filterSic;
      });
      if (data3.length > 0)
        this.serviceSearch.dataSearchLocal.filterSic = data3[0]["description"];
    } else {
      this.serviceSearch.dataSearchLocal.filterSic = "None";
    }

    localStorage.setItem(
      "DataSearch",
      JSON.stringify(this.serviceSearch.dataSearchLocal)
    );
  }

  cleanComp($event) {
    localStorage.setItem("massive", "");
    this.angForm.reset();
    this.result = $event;
    this.angForm.controls["searchData"].setValue(1);
    this.angForm.controls["DefineSearchType"].setValue(0);
    this.angForm.controls["SearchPercentage"].setValue(100);
    this.Percentage = 100;
  }

  openDialog(DataSearch): void {
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: "500px",
      data: DataSearch,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      // this.animal = result;
    });
  }

  cleanForm() {
    this.angForm.reset();
    this.angForm.controls["searchData"].setValue(1);
    this.angForm.controls["DefineSearchType"].setValue(0);
    this.angForm.controls["SearchPercentage"].setValue(100);
    this.Percentage = 100;
  }

  resultClick($event) {
    this.resultView.emit(3);
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }
    if (value >= 1000) {
      return Math.round(value / 100);
    }
    return value;
  }

  change() {
    if (this.Percentage <= 60) {
      // this.DefineSearch = 2;
    }

    if (this.Percentage >= 61 && this.Percentage < 90) {
      // this.DefineSearch = 2;
    }

    if (this.Percentage >= 90) {
      // this.DefineSearch = 0;
    }
  }

  radioChange(event) {
    this.Percentage = event.value;

    if (event.value === 0) {
      this.Percentage = 100;
    }

    if (event.value === 1) {
      this.Percentage = 80;
    }

    if (event.value === 2) {
      this.Percentage = 60;
    }
  }

  detectNav() {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(
      window.navigator.userAgent
    );

    if (isIEOrEdge) {
      this.NavIDentif = 1;
    }

    if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
      this.NavIDentif = 2;
    }

    if (navigator.userAgent.toLowerCase().indexOf("chrome") > -1) {
      this.NavIDentif = 3;
    }
  }
}
