import { Injectable } from '@angular/core';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { take, tap } from 'rxjs/operators';

import { AngularFireAuth } from '@angular/fire/auth';
import { AuthenticationService } from './Authentication.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private afAuth: AngularFireAuth,
		private authService: AuthenticationService,
		private http: HttpClient
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		// return true;
		return this.authService.afAuth.authState.pipe(
			take(1),
			map((authState) => !!authState),
			tap((authenticated) => {
				// this.loadMember();

				if (!authenticated) {
					this.router.navigate(['/login']);
				}
			})
		);
	}
}
