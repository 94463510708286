import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/core/_helpers/message.service';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  constructor(private messageService: MessageService) { }

  ngOnInit() {

  }
}
