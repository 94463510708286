import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ModalService } from 'src/app/core/_service/modal.service';
import {FileUploadRecurringComponent} from '../recurring-search/file-upload-recurring/file-upload-recurring.component';
import { SelectFiltreService } from 'src/app/core/SelectFiltre/select-filtre.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-recurring-search',
  templateUrl: './recurring-search.component.html',
  styleUrls: ['./recurring-search.component.scss']
})
export class RecurringSearchComponent implements OnInit {

  @ViewChild(FileUploadRecurringComponent, { static: false }) childFile !: FileUploadRecurringComponent;

 ngAfterViewInit(){
   this.FileUpload= this.childFile.file;
 }
  angForm= new FormGroup({
    ShowSearchData2: new FormControl(1),
    idTypeKey: new FormControl(''),
    DefineSearchType2: new FormControl(0),
    SearchPercentage2: new FormControl(),
    filterRegion2: new FormControl(''),
    CategoryPep2: new FormControl(''),
    SpecialCategory2: new FormControl(''),
    DefineRecurringType2: new FormControl(''),
    fileupload: new FormControl ('')
  });

  ShowSearchData2= 1;
  ListItems;
  ShowSearchId = 1;
  Percentage2= 100;
  DefineSearch2;
  DefineRecurring2;
  country2;
  category2;
  specialCategory2;
  FileUpload;
  fileupload;


  constructor(private modalService: ModalService, 
              private servicefilter: SelectFiltreService, 
              private fb: FormBuilder,
              private service: SelectFiltreService,
              private http: HttpClient) { }

  @Output() public componentEvent = new EventEmitter<Number>();
  ngOnInit() {
    this.loadProfileIdType();
    this.loadFilter();
  }
  loadProfileIdType() {
    this.service.TypeSearch(9).subscribe(
      result => {
        this.ListItems = result;
        localStorage.setItem('IdTypeSearch', JSON.stringify(this.ListItems));
      }
    );
  }
  clickData2(event) {
    if (event.value === 1) {
      this.ShowSearchId = 1;
    } else if (event.value === 2) {
      this.ShowSearchId = 2;
    }
  }

  change2(event) {
    this.Percentage2 = event.value;
    if (this.Percentage2 <= 80) {
      this.DefineSearch2 = 2;
    }
    if (this.Percentage2 >= 80 && this.Percentage2 < 98) {
      this.DefineSearch2 = 1;
    }
    if (this.Percentage2 >= 98) {
      this.DefineSearch2 = 0;
    }
  }

  radioChange2(event) {
    this.Percentage2 = event.value;
    console.log(event.value);
    if (event.value === 0) {
      this.Percentage2 = 98;
    }
    if (event.value === 1) {
      this.Percentage2 = 81;
    }
    if (event.value === 2) {
      this.Percentage2 = 0;
    }
  }
  
  cleanForm() {
    this.angForm.setValue({
      ShowSearchData2: 1,
      idTypeKey: '',
      DefineSearchType2: '',
      SearchPercentage2: '',
      filterRegion2: '',
      CategoryPep2: '',
      SpecialCategory2: '',
      DefineRecurringType2: 0,
      fileupload: ''});
    this.DefineSearch2= 0;
    this.Percentage2 = 100;
    this.ShowSearchId = 1;
    this.NombreArchivo = '';
    this.ArchivoRecibido = undefined;
  }
  onSubmit() {
    if (this.angForm.valid){
      if (this.ArchivoRecibido === undefined){
        alert('error sin archivo');
      }else{
        console.log(this.angForm.value);
        const formData = new FormData();
        formData.append('doc', this.fileupload);
        this.http.post('http://localhost:50810/test/doc', formData)
          .subscribe(data => {
            console.log(data);
      })
        console.log("Form Submitted!");
      }
    }else{
      alert("error");
    }
  }
  ArchivoRecibido: any;
  NombreArchivo: any;
  
  recibir($event){
    if ($event == "Ningún archivo seleccionado"){
      this.NombreArchivo = $event;
    } else{
      this.ArchivoRecibido = $event;
      this.NombreArchivo = this.ArchivoRecibido.name;
      this.fileupload = this.ArchivoRecibido;
    }  
  }
  loadFilter() {
    this.servicefilter.TypeSearch(4).subscribe((result) => {
      this.country2 = result;
      localStorage.setItem('country', JSON.stringify(this.country2));
    });
    this.servicefilter.TypeSearch(3).subscribe((result) => {
      this.category2 = result;
      localStorage.setItem('category', JSON.stringify(this.category2));
    });
    this.servicefilter.TypeSearch(7).subscribe((result) => {
      this.specialCategory2 = result;
      localStorage.setItem('specialCategory', JSON.stringify(this.specialCategory2));
    });
  }

  validationForm() {
    this.angForm = this.fb.group({
      ShowSearchData2: [1],
      idTypeKey: [''],
      DefineSearch2: ['0'],
      Percentage2: [0],
      filterRegion2: [''],
      CategoryPep2: [''],
      SpecialCategory2: ['']
    });
  }
  formatLabel(value: number | null) {
	if (!value) {
		return 0;
	}
	if (value >= 1000) {
		return Math.round(value / 100);
	}
	return value;
}
}
