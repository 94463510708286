import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { formatDate, DatePipe } from '@angular/common';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DetailsPerson } from './Search';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	// URLString = 'http://localhost:50810';
	constructor(private http: HttpClient, private datepipe: DatePipe) {}

	public dataSearchLocal;

	searchIdNumber(IdNumber) {
		debugger;
		let _httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
				responseType: 'arraybuffer',
			}),
		};

		localStorage.setItem('DataSearch', JSON.stringify(IdNumber));
		const urlSt = environment.apibackdavid + '/api/Search/SearchTypeId';
		return this.http
			.post(urlSt, IdNumber, _httpOptions)
			.pipe(catchError(this.handleError<any[]>('getEntity', [])));
	}

	searchEntity(entity): Observable<any> {
		const urlSt = environment.apibackdavid + '/api/Search/SearchEntity';
		// console.log(entity.SearchPercentage);
		this.dataSearchLocal = {
			entityName: entity.Entity,
			hitsFrom: 11,
			hitsTo: 15,
			searchType:
				typeof entity.DefineSearchType === 'undefined'
					? '1'
					: entity.DefineSearchType,
			filterRegion: entity.filterRegion,
			filterPep: entity.CategoryPep,
			filterSic: entity.SpecialCategory,
			porcentaje: entity.SearchPercentage,
			DateLocal: this.datepipe.transform(Date.now(), 'MM-dd-yyyy hh:mm:ss'),
		};

		let _httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
				responseType: 'arraybuffer',
			}),
		};

		return this.http
			.post(urlSt, this.dataSearchLocal, _httpOptions)
			.pipe(catchError(this.handleError<any[]>('getEntity', [])));
	}

	searchPerson(search): Observable<any> {
		const urlSt = environment.apibackdavid + '/api/Search/SearchName';
		const CDate = new Date(search.Birthdate);
		const CYear = new Date(search.Year);
		console.log(search.DefineSearchType);
		const year =
			typeof search.Year === 'undefined'
				? ''
				: CYear.getFullYear() + '-' + CYear.getFullYear();

		this.dataSearchLocal = {
			name: search.FirstName,
			recordType: 0,
			hitsFrom: 0,
			hitsTo: 100,
			searchType:
				typeof search.DefineSearchType === 'undefined'
					? '1'
					: search.DefineSearchType,
			filterRegion: search.filterRegion,
			filterPep: search.CategoryPep,
			filterSic: search.SpecialCategory,
			dateOfBirth:
				(typeof search.Birthdate === 'undefined' || search.Birthdate === '') &&
				(typeof search.Year === 'undefined' || search.Year === '')
					? ''
					: typeof search.Birthdate !== 'undefined' && search.Birthdate !== ''
					? formatDate(CDate, 'yyyy-MM-dd', 'en-US').toString()
					: typeof search.Year !== 'undefined'
					? year
					: '',
			porcentaje: search.SearchPercentage,
			DateLocal: this.datepipe.transform(Date.now(), 'MM-dd-yyyy hh:mm:ss'),
		};

		let _httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
				responseType: 'arraybuffer',
			}),
		};

		return this.http
			.post(urlSt, this.dataSearchLocal, _httpOptions)
			.pipe(catchError(this.handleError<any[]>('getPerson', [])));
	}

	DetailPersonEntity(idPersonEntity) {
		let _httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
				responseType: 'arraybuffer',
			}),
		};

		const urlSt =
			environment.apibackdavid + '/api/SearchDetail/DetailPE/' + idPersonEntity;

		return this.http
			.get(urlSt, _httpOptions)
			.pipe(catchError(this.handleError<any>('getPersonID', [])));
	}


	private CreateLocalDate():string
	{
		let aux = new Date();
		let year  = aux.getFullYear();
        let month = this.Padleft((aux.getMonth() + 1).toString());
		let day   = this.Padleft(aux.getDate().toString());
		let hh    = this.Padleft(aux.getHours().toString())
        let mm    = this.Padleft(aux.getMinutes().toString());
		let ss    = this.Padleft(aux.getSeconds().toString());
		return `${year}-${month}-${day}T${hh}:${mm}:${ss}`;
	}


	private Padleft(valor:string):string
	{
		while(valor.length <2)
		  valor = `0${valor}`;
      return valor;  
	}


	ShowPDF(Pid, typeSearch = null) {
		const urlSt = environment.apibackdavid + '/api/SearchDetail/DetailPDF';

		const headersPDF = new HttpHeaders().set(
			'Authorization',
			'Bearer ' + localStorage.getItem('TokenServe')
		);
		let from = localStorage.getItem('massive');		
		let d = JSON.parse(localStorage.getItem('DataSearch'));
        d.DateLocal = this.CreateLocalDate();
       const data = {
			DataSearch: JSON.stringify(d),
			ID: Pid,
			PdfType: typeSearch,
			from: from,
		};

		return this.http.post(urlSt, data, {
			headers: headersPDF,
			responseType: 'arraybuffer',
		});
	}

	ShowPDF2(Pid, DataSearch, typeSearch = null) {
		const urlSt = environment.apibackdavid + '/api/SearchDetail/DetailPDF';

		const headersPDF = new HttpHeaders().set(
			'Authorization',
			'Bearer ' + localStorage.getItem('TokenServe')
		);

		const data = {
			DataSearch: DataSearch,
			ID: Pid,
			PdfType: typeSearch,
		};

		return this.http.post(urlSt, data, {
			headers: headersPDF,
			responseType: 'arraybuffer',
		});
	}

	ShowPDF3(Pid, PageSize: number, page: number) {
		const urlSt =
			environment.apibackdavid +
			'/api/Search/mostar-pdf-busqueda-recurrente/' +
			PageSize +
			'/' +
			page;

		const headersPDF = new HttpHeaders().set(
			'Authorization',
			'Bearer ' + localStorage.getItem('TokenServe')
		);

		return this.http.get(urlSt, {
			headers: headersPDF,
			responseType: 'arraybuffer',
		});
	}

	SendEmail2(PageSize: number, page: number, email, typeSearch = null) {
		debugger;
		const urlSt =
			environment.apibackdavid +
			'/api/Search/enviar-email-pdf-busqueda-recurrente/' +
			PageSize +
			'/' +
			page;
		let from = localStorage.getItem('massive');
		const data = {
			email: email,
			id: 0,
			DataSearch: '',
			PdfType: 0,
			from: from,
		};

		let _httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
				responseType: 'arraybuffer',
			}),
		};

		return this.http
			.post(urlSt, data, _httpOptions)
			.pipe(catchError(this.handleError<any>('getDetailEmail', [])));
	}

	SendEmail(Pid, email, typeSearch = null) {
		debugger;
		const urlSt = environment.apibackdavid + '/api/SearchDetail/DetailEmail';
		let from = localStorage.getItem('massive');
		
		let d = JSON.parse(localStorage.getItem('DataSearch'));
        d.DateLocal = new Date().toISOString(); 


		const data = {
			email: email,
			id: Pid,
			DataSearch: JSON.stringify(d),
			PdfType: typeSearch,
			from: from,
		};

		let _httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
				responseType: 'arraybuffer',
			}),
		};

		return this.http
			.post(urlSt, data, _httpOptions)
			.pipe(catchError(this.handleError<any>('getDetailEmail', [])));
	}

	Verifications(idCuenta) {
		const httpOptionsVerif = {
			headers: new HttpHeaders({
				responseType: 'text',
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
			}),
		};
		const url6 = environment.apiback + '/VerificacionesRestantes?=' + idCuenta;
		return this.http.get(url6, httpOptionsVerif);
	}

	GetAccount(guid) {
		let _httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
				responseType: 'arraybuffer',
			}),
		};
		return this.http.get(
			environment.apiback + '/getaccount?id=' + guid,
			_httpOptions
		);
	}

	GetListVerificatins(desde, hasta) {
		let _httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
				responseType: 'arraybuffer',
			}),
		};
		const data = {
			desde: desde,
			hasta: hasta,
		};

		return this.http.post(
			environment.apiback + '/getListVerifications',
			data,
			_httpOptions
		);
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.error(`${operation} failed: ${error.message}`);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}
