import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
} from '@angular/core';
import { SearchService } from 'src/app/core/Search/search.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ResultDetailComponent } from '../result-detail/result-detail.component';
import { MatSnackBar, MatPaginator } from '@angular/material';

@Component({
	selector: 'app-result-search',
	templateUrl: './result-search.component.html',
	styleUrls: ['./result-search.component.scss'],
})
export class ResultSearchComponent implements OnInit {
	public urlPdf;
	Person: any;
	url = '#';
	showResult = true;
	Email;
	ShowPaina = true;
	@Input() public result;
	@Input() public paginator;
	@Input() public showDetailBtn;
	@Output() public newSearch = new EventEmitter<number>();
	@Output() public cleanBtn = new EventEmitter<number>();
	@Output() public showPaginat = new EventEmitter<boolean>();
	@ViewChild(ResultDetailComponent, { static: false })
	childResult!: ResultDetailComponent;

	showDetailButon = 0;
	showPDF = false;
	constructor(
		private servise: SearchService,
		private sanitizer: DomSanitizer,
		private _snackBar: MatSnackBar
	) {}

	displayedColumns: string[] = [
		'icon',
		'name',
		'country',
		'title',
		'subsidiario',
		'porcentaje',
	];

	ngOnInit() {
		// this.result.paginator = this.paginator;
		//console.log(this.result);
		//localStorage.setItem('result', JSON.stringify(this.result));
		//console.log(JSON.stringify(this.result, null, 5));
	}

	newSearchEven() {
		this.newSearch.emit(1);
		this.ShowPaina = false;
	}

	cleanEvent() {
		this.cleanBtn.emit(1);
	}

	ClickPerson(item) {
		this.Person = item;
	}

	selectPerson(person: any) {
		this.Person = person;
		this.showResult = false;
		this.showPaginat.emit(false);
	}

	ShowListEvent() {
		this.showResult = true;
		this.showPaginat.emit(true);
		console.log('entro aqui');
	}

	ShowReport() {
		debugger;
		console.log(this.Person);
		this.showPDF = false;
		this.servise.ShowPDF(this.Person['pid']).subscribe(
			(blob) => {
				// console.log(blob);
				debugger;
				this.urlPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
					window.URL.createObjectURL(
						new Blob([blob], { type: 'application/pdf' })
					)
				);
				this.showPDF = true;
			},
			(error) => {
				console.log('Something went wrong');
			}
		);
	}

	SendEmail() {
		debugger;
		const sendEmail = this.Email;
		this.servise.SendEmail(this.Person['pid'], sendEmail).subscribe((resp) => {
			if (resp['send'] === true) {
				this._snackBar.open('El correo se ha enviado con éxito', 'Cerrar', {
					duration: 3000,
				});
			}else
			{
				console.log(JSON.stringify(resp,null,5));
				this._snackBar.open('El correo no se pudo enviar ', 'Cerrar', {
					duration: 3000,
				});				
			}
		});
	}
}
export interface ListResult {
	pid: number;
	riskIcons: {
		riskIcon: string;
	};
	primaryName: string;
	countryCode: string;
	porcentajescore: number;
}
