import { Component, OnInit } from '@angular/core';
import { AddonsService } from '../../../shared/packs/addons.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../core/authentication/Authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { codigosstripe } from '../../../shared/urls/codigosstripe';
import { AngularFireAuth } from '@angular/fire/auth';
import { Global } from 'src/app/core/_service/global';
@Component({
	selector: 'app-pack',
	templateUrl: './pack.component.html',
	styleUrls: ['./pack.component.scss'],
})
export class PackComponent implements OnInit {
	add = new AddonsService('pack prueba', 3, 3, 3, 3, 3, 30);
	arrepack: Array<AddonsService>;
	narrepack: Array<number>;
	pack: AddonsService;
	packseleccionado: any;
	idCuenta: any;
	idCustomer: any;
	guid: any;
	restantes: any;
	arrepackcomprado: Array<any>;
	idProducto;
	NamePlan;
	payMethod = false;
	//narrepackcomprado:Array<number>;
	constructor(
		public VarGlobal: Global,
		private cod: codigosstripe,
		private http: HttpClient,
		private auth: AuthenticationService,
		private router: Router,
		private translate: TranslateService,
		public afAuth: AngularFireAuth
	) {
		const uid = this.afAuth.auth.currentUser.uid;
		this.packseleccionado = 0;
		this.http
			.get(environment.apiback + '/getaccount?id=' + uid)
			.subscribe((y) => {
				var w = JSON.stringify(y);
				var oj = JSON.parse(w);
				const url1 = environment.apiphp + '?idsub=' + oj.idMembresiaStripe;
				this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
					var arre = JSON.parse(x);
					this.NamePlan = arre.items.data[0].plan.nickname;
					this.idProducto = arre.items.data[0].plan.product;

					if (this.idProducto == this.cod.demo) {
						this.router.navigate(['/afterlogin']);
					}
				});
			});
		//this.NamePlan = localStorage.getItem('productNamePlan');
	}

	ngOnInit() {
		this.verificarMetodo();
		this.translate.use(localStorage.getItem('language'));
		this.VarGlobal.SearchCount();
		this.arrepack = new Array<AddonsService>();
		this.http.get(environment.apiback + '/getpack').subscribe((y) => {
			var w = JSON.stringify(y);
			var oj = JSON.parse(w);
			oj.forEach((x) => {
				var metadata = JSON.parse(x.metadataPack);
				var nombre = x.name;
				var precio = metadata.precio;
				var cupos = metadata.verificaciones;
				this.pack = new AddonsService(nombre, precio, 0, cupos, 0, 0, 0);
				this.arrepack.push(this.pack);
			});
		});

		// this.guid = localStorage.getItem('uid');
		this.guid = this.afAuth.auth.currentUser.uid;
		this.http
			.get(environment.apiback + '/getaccount?id=' + this.guid)
			.subscribe((y) => {
				var w = JSON.stringify(y);
				var oj = JSON.parse(w);

				this.idCuenta = oj.idCuenta;
				this.idCustomer = oj.idCustomer;
				const url6 =
					environment.apiback + '/VerificacionesRestantes?=' + this.idCuenta;
				this.http.get(url6, { responseType: 'text' }).subscribe((x) => {
					this.restantes = x;
				});

				this.arrepackcomprado = new Array<any>();
				this.http
					.get(environment.apiback + '/getPackCuenta?id=' + this.idCuenta)
					.subscribe((y) => {
						var w = JSON.stringify(y);
						var oj = JSON.parse(w);
						oj.forEach((x) => {
							this.arrepackcomprado.push(x);
						});
					});
			});
	}

	Comprar() {
		var pack = this.arrepack.find((x) => x.nombre == this.packseleccionado);
		var precio = parseFloat(pack.precio) * 100;
		const url1 =
			environment.apiphp +
			'?comprarpack=true&nombre=' +
			this.packseleccionado +
			'&precio=' +
			precio +
			'&customer=' +
			this.idCustomer;
		const url2 = environment.apiback + '/registerPackCuenta';

		const dataX = {
			IDCuenta: this.idCuenta,
			Verificaciones: pack.cupos,
			/*       StartDate: Date.now(),
            ExpirationDate: Date.now() */
		};

		this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
			this.http.post(url2, dataX).subscribe((y) => {
				const w = JSON.stringify(y);
				const oj = JSON.parse(w);
				this.router.navigate([
					'/agradecimiento',
					'Gracias por adquirir el Pack',
					pack.cupos + ' Verificaciones',
				]);
			});
		});
	}
	verificarMetodo() {
		var uid = localStorage.getItem('uid');
		this.http
			.get(environment.apiback + '/getaccount?id=' + uid)
			.subscribe((y) => {
				var w = JSON.stringify(y);
				var oj = JSON.parse(w);
				this.idCustomer = oj.idCustomer;
				try {
					const url1 =
						environment.apiphp + '?consultarcustomersource=' + this.idCustomer;
					this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
						var arre = JSON.parse(x);
						if (
							arre.default_source === undefined ||
							arre.default_source === '' ||
							arre.default_source === null
						) {
							this.payMethod = true;
						} else {
							this.payMethod = false;
						}
					});
				} catch (e) {
					console.log(e.message);
				}
			});
	}

	changeLeng($event) {
		this.translate.use($event);

		localStorage.setItem('language', $event);
	}
}
