import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as admin from 'firebase-admin';
import * as firebase from 'firebase/app';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
	AngularFirestore,
	AngularFirestoreDocument,
} from '@angular/fire/firestore';

import { map, isEmpty } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { User, Roles } from '../../core/authentication/user';
import { Search } from 'src/app/core/Search/Search';
import { formatDate } from '@angular/common';
import { AgradecimientoComponent } from 'src/app/modules/home/pages/agradecimiento/agradecimiento.component';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthenticationService {
	user: Observable<User>;
	userprofile: User;
	public Token: string;

	httpOptions = {
		headers: new HttpHeaders({
			Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
		}),
	};

	//URLString = 'http://localhost:50810';
	constructor(
		private http: HttpClient,
		public afAuth: AngularFireAuth,
		private afs: AngularFirestore
	) {
		this.user = this.afAuth.authState.pipe(
			switchMap((user) => {
				if (user) {
					return firebase
						.database()
						.ref('users/' + user.uid)
						.once('value')
						.then(function (snapshot) {
							var username =
								(snapshot.val() && snapshot.val().username) || 'Anonymous';
							// console.log(username);
						});
					// return this.afs.doc<User>(`users/` + user.uid).valueChanges();
				} else {
					return of(null);
				}
			})
		);
	}

	loginGoogle() {
		return this.oAuthLogin(new firebase.auth.GoogleAuthProvider());
	}

	registerUser(userRegister: User, pass: string, IsSon: boolean = true) {
		return new Promise((resolve, reject) => {
			this.afAuth.auth
				.createUserWithEmailAndPassword(userRegister.email, pass)
				.then(
					(userData) => {
						userRegister.uid = userData.user.uid;
						// this.loginserver(userData.user.uid);
						localStorage.setItem('userNew', userData.user.uid);
						if (IsSon) {
							this.loginEmail(
								localStorage.getItem('email'),
								localStorage.getItem('pass')
							);
							// console.log('Entro en hijo');
						} else {
							this.loginEmail(userRegister.email, pass);
							// console.log('Entro en padre');
						}

						// localStorage.setItem('uid', userData.user.uid);

						userData.user.getIdToken(true).then((token) => {
							localStorage.setItem('token', token);
							// this.loginserver(token).subscribe((data: any) => console.log('retorno' + data), error => console.log(error.message));
						});
						localStorage.setItem('uidCreat', userData.user.uid);
						localStorage.setItem('language', 'es');
						// this.RecordUserData(userRegister);

						resolve(userData.user.uid);
					},
					(err) => reject(err)
				);
		});
	}

	loginEmail(email: string, pass: string) {
		localStorage.setItem('email', email);
		localStorage.setItem('pass', pass);

		return new Promise((resolve, reject) => {
			this.afAuth.auth.signInWithEmailAndPassword(email, pass).then(
				(userData) => {
					resolve(userData);

					this.loginserver(userData.user.uid);
					localStorage.setItem('uid', userData.user.uid);

					userData.user.getIdToken(true).then((token) => {
						localStorage.setItem('token', token);
						// this.loginserver(token).subscribe((data: any) => console.log('retorno' + data), error => console.log(error.message));
					});
				},
				(err) => reject(err)
			);
		});
	}

	private oAuthLogin(provider) {
		return this.afAuth.auth.signInWithPopup(provider).then((credential) => {
			this.updateUserData(credential.user);
			// this.router.navigate(['/admin']);
		});
	}

	public updateUser(userData: User) {
		return this.afAuth.auth.currentUser
			.updateProfile({
				displayName: userData.displayName,
				photoURL: userData.photoURL,
			})
			.then(function () {
				// Update successful.
			})
			.catch(function (error) {
				// An error happened.
			});
	}

	private updateUserData(user) {
		//console.log(user);

		const userRef: AngularFirestoreDocument<any> = this.afs.doc(
			`users/${user.uid}`
		);

		const data: any = {
			uid: user.uid,
			email: user.email,
			displayName: user.displayName,
			displayFirstName: user.displayFirstName,
			displayLastName: user.displayLastName,
			parentUser: 'nohay',
			rol: { user: false, admin: true },
			language: localStorage.getItem('language'),
		};

		// this.updateUser(data);
		firebase
			.database()
			.ref('users/' + user.uid)
			.push(data);
		// return userRef.set(Object.assign(data), { merge: true });
	}

	public RecordUserData(user: User) {
		//console.log(user);

		//const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

		const data: any = {
			uid: user.uid,
			email: user.email,
			displayName: user.displayName,
			displayFirstName: user.displayFirstName,
			displayLastName: user.displayLastName,
			parentUser: user.parentUser === 'is padre' ? 'nohay' : user.parentUser,
			rol: user.roles,
			language: localStorage.getItem('language'),
		};

		// this.updateUser(data);
		console.log(
			firebase
				.database()
				.ref('users/' + user.uid)
				.set(data)
		);
		//firebase.database().ref('users/' + user.uid).set(data);
		// return userRef.set(Object.assign(data), { merge: true });
	}

	getAuth() {
		return this.afAuth.authState.pipe(map((auth) => auth));
	}

	logout() {
		localStorage.clear();
		return this.afAuth.auth.signOut();
	}

	eliminar() {
		return this.afAuth.auth.currentUser.delete();
	}

	resetPassword(email: string): Promise<any> {
		let actionOptions = {
			url: environment.urlRedirect,
		};
		return this.afAuth.auth.sendPasswordResetEmail(email, actionOptions);
	}

	changePassword(email: string, oldePassword: string, newPassword: string) {
		return this.afAuth.auth
			.signInWithEmailAndPassword(email, oldePassword)
			.then(function (userCredential) {
				userCredential.user.updatePassword(newPassword);
			});
	}

	loginserver(googleUid) {
		const urlLocal = environment.apibackdavid + '/Authentication/login';
		const LoginModel = {
			googleUid: googleUid,
		};

		return this.http.post(urlLocal, LoginModel);
	}

	GetUserSon(parentUser): Promise<User[]> {
		/* return this.afs.collection<User>('users', ref => ref.where('parentUser', '==', parentUser))
      .valueChanges(); */
		return new Promise((resolve, reject) => {
			firebase
				.database()
				.ref('users')
				.orderByChild('parentUser')
				.equalTo(parentUser)
				.once('value')
				.then((snapshot) => {
					// var username = (snapshot.val() && snapshot.val() != null) || 'Anonymous';
					// console.log(snapshot.val());
					resolve(snapshot.val());
				}),
				(err) => reject(err);
		});
	}
	// : Observable<User[]>
	GetUserProfile(uid): Observable<User[]> {
		/* firebase.database().ref('/users/' + uid).once('value').then(function(snapshot) {
       //var username = (snapshot.val() && snapshot.val().username) || 'Anonymous';
       this.userprofile.uid = snapshot.val().uid;
       this.userprofile.email = snapshot.val().email;
       this.userprofile.displayName = snapshot.val().displayName;
       this.userprofile.displayFirstName = snapshot.val().displayFirstName;
       this.userprofile.displayLastName = snapshot.val().displayLastName;
       this.userprofile.parentUser = snapshot.val().parentUser;
       this.userprofile.rol = snapshot.val().rol;
       this.userprofile.language = snapshot.val().language;
       return this.userprofile;

     });*/
		//return this.userprofile;
		return this.afs
			.collection<User>('users', (ref) => ref.where('uid', '==', uid))
			.valueChanges();
	}

	registerUserBack(user: User, pass: string, isSong: boolean = true) {
		const uid = localStorage.getItem('uid');
		const data = {
			Email: user.email,
			FirstName: user.displayFirstName,
			LastName: user.displayLastName,
			GoogleUid: user.uid,
			ProfilePhotoSmallUrl: '',
			ProfilePhotoUrl: '',
			TimeZone: '',
			UserId: localStorage.getItem('uidCreat'),
			ParentUser: isSong ? uid : null,
			language: localStorage.getItem('language'),
			PasswordHash: pass,
			Company: user.company,
			Position: user.position,
			Direction: user.direction,
			Country: user.country,
			Phone: user.phone,
			City: user.city,
		};

		const urlLocal = environment.apibackdavid + '/api/user/create';

		return this.http.post(urlLocal, data);
	}

	changeLanguaje(languaje: string) {
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
				responseType: 'arraybuffer',
			}),
		};
		const urlLocal =
			environment.apibackdavid + '/api/Search/ChangeLanguage/' + languaje;
		return this.http.get(urlLocal, httpOptions);
	}

	getProfileBack(uid) {
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
				responseType: 'arraybuffer',
			}),
		};
		return this.http.get<User>(
			environment.apibackdavid + '/api/user/getUser/' + uid,
			httpOptions
		);
	}

	PutProfileBack(user: User) {
		const httpOptions = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
				responseType: 'arraybuffer',
			}),
		};
		const data = {
			FirstName: user.firstName,
			LastName: user.lastName,
			Company: user.company,
			Position: user.position,
			Direction: user.direction,
			Country: user.country,
			Phone: user.phone,
			City: user.city,
		};

		return this.http.put(
			environment.apibackdavid + '/api/user/Profile/' + user.uid,
			data,
			httpOptions
		);
	}
}
