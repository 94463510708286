import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { MessageService } from './core/_helpers/message.service';
import { Router } from '@angular/router';
import { LoaderService } from './core/_helpers/loader.service';
import { finalize } from 'rxjs/operators';

export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private messageService: MessageService, public loaderService: LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.show();

    return next.handle(request)
      .pipe(
        retry(1),
        finalize(() => this.loaderService.hide()),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }
          // window.alert(errorMessage);
          this.log(errorMessage);
          return throwError(errorMessage);
        })
      );
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`RiskIdService: ${message}`);
  }
}
