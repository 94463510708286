import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Plans } from '../../urls/plans';
import { codigosstripe } from '../../urls/codigosstripe';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss']
})
export class SubMenuComponent implements OnInit {

  @Output() public cleanBtn = new EventEmitter<number>();
  @Output() public result = new EventEmitter<number>();
  @Input() public activeBtn: boolean;
  planSt;
  constructor(private type: Plans, private planStr: codigosstripe) {

  }

  plan;
  ngOnInit() {
    this.plan = localStorage.getItem('plan');
    this.planSt = this.planStr;
  }

  ClickCleanBtn() {
    this.cleanBtn.emit(1);
  }

  ClickSearch(view) {
    this.result.emit(view);
  }
}
