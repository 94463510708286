import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { LoginComponent } from './modules/home/pages/login/login.component';
import { SignupComponent } from './modules/home/pages/signup/signup.component';
import { ForgotComponent } from './modules/home/pages/forgot/forgot.component';
import { AfterLoginComponent } from './modules/home/pages/afterlogin/afterlogin.component';
import {
	HttpClientModule,
	HttpClient,
	HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { SidebarComponent } from './modules/home/pages/components/sidebar/sidebar.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AuthenticationService } from './core/authentication/Authentication.service';
import { AuthGuard } from './core/authentication/auth.guard';
import {
	FlashMessagesModule,
	FlashMessagesService,
} from 'angular2-flash-messages';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import * as firebase from 'firebase';
import { Module as StripeModule } from 'stripe-angular';
// Material
import {
	NoopAnimationsModule,
	BrowserAnimationsModule,
} from '@angular/platform-browser/animations';
import { CustomMaterialModule } from '../app/core/_helpers/CustomMaterialModule';
import { SubMenuComponent } from './shared/components/sub-menu/sub-menu.component';
import { DirectorysComponent } from './modules/access/directorys/directorys.component';
import { ThankYouPageComponent } from './modules/home/pages/thank-you-page/thank-you-page.component';
import { AccessComponent } from './modules/access/access.component';
import { WorkspaceSeetingsComponent } from './modules/workspace/workspace-seetings/workspace-seetings.component';
import { FullScreenOverlayComponent } from './shared/components/full-screen-overlay/full-screen-overlay.component';
import { ProfileSettingsComponent } from './modules/profile/profile-settings/profile-settings.component';
import { SettingsAllUserComponent } from './modules/profile/settings-all-user/settings-all-user.component';
import { UsersComponent } from './modules/profile/users/users.component';
import { AddUserComponent } from './modules/profile/add-user/add-user.component';
import { ChangePasswordComponent } from './modules/profile/change-password/change-password.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Language } from './core/_helpers/Language';
import { SearchComponent } from './modules/search/search.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ResultSearchComponent } from './modules/search/result-search/result-search.component';
import { SubMenuResultComponent } from './shared/components/sub-menu-result/sub-menu-result.component';
import { CallCenterComponent } from './modules/home/pages/call-center/call-center.component';
import { SearchIdComponent } from './modules/search/search-id/search-id.component';
import { UpgradeComponent } from './modules/account/upgrade/upgrade.component';
import { PackComponent } from './modules/account/pack/pack.component';
import { PaymentmethodComponent } from './modules/account/paymentmethod/paymentmethod.component';
import { PlanesMembresiaComponent } from './modules/home/pages/planes-membresia/planes-membresia.component';
import { ResultDetailComponent } from './modules/search/result-detail/result-detail.component';
import { SearchPersonComponent } from './modules/search/search-person/search-person.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { CheckoutComponent } from './modules/home/pages/checkout/checkout.component';
import { LoaderService } from './core/_helpers/loader.service';
import { LoaderInterceptor } from './core/_helpers/LoaderInterceptor';
import { AgradecimientoComponent } from './modules/home/pages/agradecimiento/agradecimiento.component';
import { HttpErrorInterceptor } from './interceptor';
import { MessagesComponent } from './shared/components/messages/messages.component';
import { ResultDetailPersonComponent } from './modules/search/result-detail-person/result-detail-person.component';
import { ResultDetailEntityComponent } from './modules/search/result-detail-entity/result-detail-entity.component';
import { SearchService } from './core/Search/search.service';
import { SelectFiltreService } from './core/SelectFiltre/select-filtre.service';
import { MasssiveSearchComponent } from './modules/search/masssive-search/masssive-search.component';
import { FileUploadComponent } from './modules/search/masssive-search/file-upload/file-upload.component';
import { RecurringSearchComponent } from './modules/search/recurring-search/recurring-search.component';
import { codigosstripe } from './shared/urls/codigosstripe';
import { Plans } from './shared/urls/plans';
import { DatePipe } from '@angular/common';
import { DialogOverviewComponent } from './shared/components/modal/dialog-overview/dialog-overview.component';
import { NoResultComponent } from './modules/search/no-result/no-result.component';
import { FileUploadRecurringComponent } from './modules/search/recurring-search/file-upload-recurring/file-upload-recurring.component';
import { PruebaComponent } from './modules/search/masssive-search/prueba/prueba.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MassSearchResultsComponent } from './modules/search/mass-search-results/mass-search-results.component';
import { Global } from './core/_service/global';
import { ListVerificationsComponent } from './modules/account/list-verifications/list-verifications.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getDutchPaginatorIntl } from './core/_helpers/paginator-intl';
import { RecurringsearchComponent } from './modules/recurringsearch/recurringsearch.component';
import { MaterialModule } from './material-module';
import { FiltrarTablaRecurrentePipe } from './modules/pipes/filtrar-tabla-recurrente.pipe';
import { environment } from 'src/environments/environment';
import { RecurringsearchreportComponent } from './modules/report/recurringsearchreport/recurringsearchreport.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		AfterLoginComponent,
		SignupComponent,
		ForgotComponent,
		SidebarComponent,
		NavbarComponent,
		SubMenuComponent,
		DirectorysComponent,
		ThankYouPageComponent,
		AccessComponent,
		WorkspaceSeetingsComponent,
		FullScreenOverlayComponent,
		ProfileSettingsComponent,
		SettingsAllUserComponent,
		UsersComponent,
		AddUserComponent,
		ChangePasswordComponent,
		SearchComponent,
		ResultSearchComponent,
		SubMenuResultComponent,
		CallCenterComponent,
		SearchIdComponent,
		UpgradeComponent,
		PackComponent,
		PaymentmethodComponent,
		PlanesMembresiaComponent,
		ResultDetailComponent,
		SearchPersonComponent,
		LoaderComponent,
		CheckoutComponent,
		AgradecimientoComponent,
		MessagesComponent,
		ResultDetailPersonComponent,
		ResultDetailEntityComponent,
		MasssiveSearchComponent,
		FileUploadComponent,
		RecurringSearchComponent,
		DialogOverviewComponent,
		NoResultComponent,
		FileUploadRecurringComponent,
		PruebaComponent,
		MassSearchResultsComponent,
		ListVerificationsComponent,
		RecurringsearchComponent,
		FiltrarTablaRecurrentePipe,
		RecurringsearchreportComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		AngularFirestoreModule,
		AngularFireStorageModule,
		AngularFireAuthModule,
		StripeModule.forRoot(),
		AngularFireModule.initializeApp(environment.firebase),
		FlashMessagesModule,
		NoopAnimationsModule,
		CustomMaterialModule,
		AngularFireStorageModule,
		AngularFireDatabaseModule,
		MaterialModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => {
					return new TranslateHttpLoader(http);
				},
				deps: [HttpClient],
			},
		}),
		BsDatepickerModule.forRoot(),
		BrowserAnimationsModule,
		ReactiveFormsModule,
		NgxFileDropModule,
	],
	providers: [
		AuthenticationService,
		AuthGuard,
		Global,
		codigosstripe,
		Plans,
		SearchService,
		SelectFiltreService,
		FlashMessagesService,
		Language,
		LoaderService,
		DatePipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		},
		{ provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
		// { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
	],

	bootstrap: [AppComponent],
	entryComponents: [DialogOverviewComponent],
})
export class AppModule {}
