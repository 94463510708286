import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SearchService } from 'src/app/core/Search/search.service';
import { MatSnackBar, MatPaginator } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-no-result',
	templateUrl: './no-result.component.html',
	styleUrls: ['./no-result.component.scss'],
})
export class NoResultComponent implements OnInit {
	@Input() bandShow;
	@Input() public showDetailBtn;
	constructor(
		private servise: SearchService,
		private _snackBar: MatSnackBar,
		private sanitizer: DomSanitizer
	) {}
	data;
	@Output() public newSearch = new EventEmitter<number>();
	@Output() public cleanBtn = new EventEmitter<number>();
	country;
	category;
	specialCategory;
	typeSearch;
	IdTypeSearch;
	type;
	Person: any;
	Email;
	showPDF = false;
	public urlPdf;
	ngOnInit() {
		console.log(this.bandShow);
		if (this.bandShow === 3) {
			this.data = JSON.parse(localStorage.getItem('DataSearch'));

			const IdType = <any[]>JSON.parse(localStorage.getItem('IdTypeSearch'));
			this.IdTypeSearch = IdType.filter((it) => {
				return it.value.includes(this.data.idTypeKey);
			});
			this.typeSearch = localStorage.getItem('showTypeSearch');
			// console.log(this.typeSearch);

			this.data.idTypeKey = this.IdTypeSearch[0]['description'];

			localStorage.setItem('DataSearch', JSON.stringify(this.data));
		} else if (this.bandShow === 2 || this.bandShow === 1) {
			this.data = JSON.parse(localStorage.getItem('DataSearch'));
			this.typeSearch = localStorage.getItem('showTypeSearch');
			console.log(this.data);
			this.country = this.data.filterRegion;
			this.category = this.data.filterPep;
			this.specialCategory = this.data.filterSic;
		}
	}
	newSearchEven() {
		this.newSearch.emit(1);
	}

	cleanEvent() {
		this.cleanBtn.emit(1);
	}
	SendEmail() {
		let Type = 0;

		if (this.bandShow === 2) {
			Type = 2;
		}
		if (this.bandShow === 1) {
			Type = 1;
		}

		const sendEmail = this.Email;
		this.servise.SendEmail(0, sendEmail, Type).subscribe((resp) => {
			if (resp['send'] === true) {
				this._snackBar.open('El correo se ha enviado con éxito', 'Cerrar', {
					duration: 3000,
				});
			}
		});
	}
	ShowReport() {
		debugger;
		let Type = 0;

		if (this.bandShow === 2) {
			Type = 2;
		}
		if (this.bandShow === 1) {
			Type = 1;
		}

		if (this.bandShow === 3) {
			Type = 3;
		}

		this.showPDF = false;
		this.servise.ShowPDF(0, Type).subscribe(
			(blob) => {
				// console.log(blob);
				this.urlPdf = this.sanitizer.bypassSecurityTrustResourceUrl(
					window.URL.createObjectURL(
						new Blob([blob], { type: 'application/pdf' })
					)
				);
				this.showPDF = true;
			},
			(error) => {
				console.log('Something went wrong');
			}
		);
	}
}
