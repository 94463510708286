import { AuthenticationService } from '../authentication/Authentication.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireAuth } from '@angular/fire/auth';


@Injectable()
export class Language {

    constructor(private auth: AuthenticationService,
        private translate: TranslateService,
        public afAuth: AngularFireAuth) {
    }

    public listUser;
    public language: string;

    public IniProfile() {
        const uid = this.afAuth.auth.currentUser.uid;
        // const id = localStorage.getItem('uid');
        return this.auth.GetUserProfile(uid);
    }

    IniLenguaje(leng: string) {
        this.translate.setDefaultLang(leng);
    }

}
