import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SelectFiltreService } from './../../../core/SelectFiltre/select-filtre.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IdType } from 'src/app/core/Search/Search';
import { MatSnackBar } from '@angular/material';
import { SearchService } from 'src/app/core/Search/search.service';

@Component({
	selector: 'app-search-id',
	templateUrl: './search-id.component.html',
	styleUrls: ['./search-id.component.scss'],
})
export class SearchIdComponent implements OnInit {
	ListItems;
	angFormId: FormGroup;
	searchID = new IdType();
	@Output() resultView = new EventEmitter<number>();
	@Output() public bandShowNoResult = new EventEmitter<number>();
	showMessage;

	constructor(
		private service: SelectFiltreService,
		private fb: FormBuilder,
		private serviceSearch: SearchService,
		private _snackBar: MatSnackBar
	) {}

	ngOnInit() {
		this.loadProfileIdType();
		this.validationForm();
		localStorage.setItem('showTypeSearch', '1');
	}

	loadProfileIdType() {
		this.service.TypeSearch(9).subscribe((result) => {
			this.ListItems = result;
			localStorage.setItem('IdTypeSearch', JSON.stringify(this.ListItems));
		});
	}

	validationForm() {
		this.angFormId = this.fb.group({
			idTypeKey: [''],
			idTypeValue: [''],
		});
	}

	get idTypeKey() {
		return this.angFormId.get('idTypeKey');
	}
	get idTypeValue() {
		return this.angFormId.get('idTypeValue');
	}

	onSubmitID() {
		this.serviceSearch.Verifications(0).subscribe((res) => {
			if (res > 0) {
				this.buscar();
			} else {
				this._snackBar.open('Usted no posee cupos disponibles', 'Cerrar', {
					duration: 10000,
				});
			}
		});
	}

	public buscar() {
		this.searchID = {
			idTypeKey: this.angFormId.controls['idTypeKey'].value,
			idTypeValue: this.angFormId.controls['idTypeValue'].value,
		};
		this.serviceSearch.searchIdNumber(this.searchID).subscribe((result) => {
			this.ListItems = result;

			this.bandShowNoResult.emit(3);
			if (this.ListItems.length > 0) {
				this.resultView.emit(2);
			} else {
				this.resultView.emit(4);
			}
			// tslint:disable-next-line: no-unused-expression
		}),
			(err) => {
				this._snackBar.open(err, 'Cerrar', {
					duration: 3000,
				});
			};
	}

	resultClick($event) {
		this.resultView.emit(1);
	}

	cleanComp($event) {
		this.angFormId.reset();
	}
}
