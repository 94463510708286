import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../core/authentication/Authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from './../../../../core/_helpers/Language';
import { codigosstripe } from 'src/app/shared/urls/codigosstripe';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
	selector: 'app-after-login',
	templateUrl: './afterlogin.component.html',
	styleUrls: ['./afterlogin.component.scss'],
})
export class AfterLoginComponent implements OnInit {
	public Email: string;
	public Token: string;
	public isLogin: boolean;
	public nombreUsuario: string;
	public listUser;
	public nameLabe;
	public lang;
	public ComponentView = 0;
	public MasssiveSearch = false;
	public recurringList: boolean = true;
	resultSearchdata;
	NombreArchivo;
	ArchivoRecibido;
	fileupload;
	showListResult;
	codProdStr;
	TypeSearch;
	constructor(
		private router: Router,
		public auth: AuthenticationService,
		private translate: TranslateService,
		private language: Language,
		private codProduct: codigosstripe,
		public afAuth: AngularFireAuth
	) {
		this.codProdStr = codProduct;
		console.log(JSON.stringify(this.codProdStr, null, 5));
	}

	plan;
	ngOnInit() {
		this.plan = localStorage.getItem('plan');
		this.LoadProfile();
		// console.log(this.codProdStr);
	}
	getshowresult(message: boolean) {
		this.MasssiveSearch = message;
	}
	Logout() {
		this.auth.logout().then(
			(user) => {
				localStorage.clear();
				this.router.navigate(['/login']);
			},
			(error) => {
				alert(error.message);
			}
		);
	}
	token() {
		firebase
			.auth()
			.currentUser.getIdToken(false)
			.then((idToken) => {
				this.Token = idToken;
			})
			.catch((error) => alert(error.message));
	}
	LoadProfile() {
		this.lang = localStorage.getItem('language');
		// console.log("idioma:"+this.lang);
		// this.translate.setDefaultLang(this.lang);
		// console.log(this.lang);
		this.translate.use(this.lang);
		// console.log(this.lang);
		const id = this.afAuth.auth.currentUser.uid;
		firebase
			.database()
			.ref('users/' + id)
			.once('value')
			.then((snapshot) => {
				var username =
					(snapshot.val() && snapshot.val() != null) || 'Anonymous';
				if (snapshot.val() !== null) {
					this.lang = snapshot.val().language;
					this.translate.setDefaultLang(this.lang);
					// this.translate.use(this.lang);
					localStorage.setItem('language', this.lang);
				} else {
					this.translate.setDefaultLang(this.lang);
				}

				this.translate
					.get(['verificationTools.texto'])
					.subscribe((translations) => {
						this.nameLabe = translations['verificationTools.texto'];
					});
			});

		/*this.language.IniProfile().subscribe(
      (response: any) => {
        if (typeof response[0]['language'] !== 'undefined') {
  
          this.lang = response[0]['language'];
          this.translate.setDefaultLang(this.lang);
          // this.translate.use(this.lang);
          localStorage.setItem('language', this.lang);
        } else {
          this.translate.setDefaultLang(this.lang);
        }
  
  
        this.translate.get(['verificationTools.texto'])
          .subscribe(translations => {
            this.nameLabe = translations['verificationTools.texto'];
          });
      });*/
	}

	changeLeng($event) {
		this.translate.use($event);

		localStorage.setItem('language', $event);

		this.translate
			.get(['verificationTools.texto'])
			.subscribe((translations) => {
				this.nameLabe = translations['verificationTools.texto'];
			});
	}

	eventBack($event) {
		this.ComponentView = $event;
	}

	showHeptcenter($event) {
		this.ComponentView = $event;
	}

	resultSearch($event) {
		this.resultSearchdata = $event;
		// console.log($event);
	}

	newSearch($event) {
		this.MasssiveSearch = false;
	}

	recibir($event) {
		if ($event === 'Ningún archivo seleccionado') {
			this.NombreArchivo = $event;
		} else {
			this.ArchivoRecibido = $event;
			this.NombreArchivo = this.ArchivoRecibido.name;
			this.fileupload = this.ArchivoRecibido;
		}
	}

	showList($event) {
		this.showListResult = $event;
	}

	resultTypeSearch($event) {
		this.TypeSearch = $event;
	}
}
