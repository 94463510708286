/**
 * Codigo de utilidades
 *
 * 
 * ```typescript
 * // Or you can specify the language explicitly
 * const instance = new Helpers();
 * ```
 */
export class Helpers {

    /**
    * @param jsonEvil  Identifica las keys en un Array.
    */
    public ConvertDetails(jsonEvil) {

        const goodResp = [];
        const evilProps = jsonEvil;
        if (evilProps != null) {
            for (const prop of Object.keys(evilProps)) {
                goodResp.push(evilProps[prop]);
            }

        }
        return goodResp;
    }
}
