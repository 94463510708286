import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../core/authentication/Authentication.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  Email: string;
  constructor(private router: Router,
    private auth: AuthenticationService,
    private flashMessage: FlashMessagesService,
    private translate: TranslateService) { }

  ngOnInit() {
    const leng = localStorage.getItem('language');
    this.translate.setDefaultLang(leng);
  }

  Resetpassword() {
    this.auth.resetPassword(this.Email).then(() => {
      this.flashMessage.show('An email has been sent to retrieve your password',
        { cssClass: 'alert-success', timeout: 4000 });
      this.router.navigate(['/login']);
    }).catch(err => {
      this.flashMessage.show(err, { cssClass: 'alert-danger', timeout: 4000 });
    });
  }
}
