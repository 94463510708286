import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/Authentication.service';
import { User } from 'src/app/core/authentication/user';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/core/_helpers/Language';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  user: User = new User();
  email: string;
  oldPassword: string;
  newPassword: string;

  constructor(private auth: AuthenticationService, private _snackBar: MatSnackBar,
    private languaje: Language) { }

  @Output() public resultChange = new EventEmitter<number>();
  @Input() public lenguaje: string;

  ngOnInit() {
    this.oldPassword = '';
    this.newPassword = '';

    this.auth.getAuth().subscribe(
      res => {
        this.email = res.email;

      },
      err => {
        console.log(err);
      },
    );

    this.lenguajeIni();
  }
  onSubmit() {

    console.log(this.email + ', ' + this.oldPassword);
    this.auth.changePassword(this.email, this.oldPassword, this.newPassword).then((succes) => {
      // console.log({ html: 'Se ha cambiado con exito la contraseña' });
      this._snackBar.open('Se ha cambiado con exito la contraseña', 'Cerrar', {
        duration: 3000
      });

      this.resultChange.emit(1);
    }, (error) => {

      console.log({ html: 'Error:' + error.message });
    });
  }

  back() {
    this.resultChange.emit(1);
  }

  lenguajeIni() {
    this.languaje.IniLenguaje(this.lenguaje);
  }
}
