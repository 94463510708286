import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable()
export class SelectFiltreService {
	// URLString = 'http://localhost:50810';
	constructor(private http: HttpClient) {}
	httpOptions = {
		headers: new HttpHeaders({
			Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
			responseType: 'arraybuffer',
		}),
	};

	TypeSearch(typeSearch): Observable<any> {
		return this.http
			.get(
				environment.apibackdavid + '/api/SelectFiltre/TypeSearch/' + typeSearch,
				this.httpOptions
			)
			.pipe(retry(1), catchError(this.handleError));
	}

	// Error handling
	handleError(error) {
		let errorMessage = '';
		if (error.error instanceof ErrorEvent) {
			// Get client-side error
			errorMessage = error.error.message;
		} else {
			// Get server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		window.alert(errorMessage);
		return throwError(errorMessage);
	}
}
