import { storage } from 'firebase';

// activar para produccion
export class codigosstripe {
    planpack1 = '1 verificación';
    planpack2 = '10 verificaciónes';
    packs = 'prod_G5XpUZ7a3evHcN';
    demo = 'prod_G0b6QN1A3gBPaJ';
    super = 'prod_FxyOu4s73gFTaJ';
    essential = 'prod_FxyGbu79St9eok';
    standard = 'prod_FxyIEtOxT2xRMu';
    pro = 'prod_FxyKUFwQ06svR9';
    ultra = 'prod_FxyLY6QwKjfu76';
    ultra2 = 'prod_Ly1kDVkD5PBJrM';
    apistripe = 'pk_live_EpmWDolsdYReNJNSfWgySlhA00C0VhVGaQ';
}

 // activar para pruebas
/*    export class codigosstripe {
    planpack1 = '1 verificación';
    planpack2 = '10 verificaciónes';
    packs = 'prod_JXrDjjKf5kSpTK';
    demo = 'prod_G0b6QN1A3gBPaJ';
    super = 'prod_JAv1SXnsfXKyz';
    essential = 'prod_JAuxfHaWYoGGj0';
    standard = 'prod_HgPRCx6pV0tRY4';
    pro = 'prod_JAuyHro5TZBLRG';
    ultra = 'prod_JAuzx3ezQd3ziq';
    ultra2 = 'prod_Ly1kDVkD5PBJrM';
    apistripe = 'pk_test_omCxa4gEsj3kEuAMRNV7IQd400YV8rkqmH';
}
 */
