import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-agradecimiento',
  templateUrl: './agradecimiento.component.html',
  styleUrls: ['./agradecimiento.component.scss']
})
export class AgradecimientoComponent implements OnInit {
   public mensaje: string;
   public plan: string;
  constructor(private rutaActiva: ActivatedRoute, private translate: TranslateService) {
    //Gracias por adquirir el Plan
    this.mensaje = this.rutaActiva.snapshot.params.mensaje;
    this.plan = this.rutaActiva.snapshot.params.plan;
   }

  ngOnInit() {

  }

  changeLeng($event) {
	this.translate.use($event);

	localStorage.setItem('language', $event);
  }

}
