import { Component, OnInit, ViewChild } from '@angular/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';

import { FormGroup } from '@angular/forms';
import { SearchPersonComponent } from './search-person/search-person.component';
import { SearchIdComponent } from './search-id/search-id.component';
import { MatPaginator } from '@angular/material';
import { ResultSearchComponent } from './result-search/result-search.component';
import { SelectFiltreService } from 'src/app/core/SelectFiltre/select-filtre.service';

defineLocale('ed', deLocale);

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @ViewChild(SearchPersonComponent, { static: false })
  childSearchPerson!: SearchPersonComponent;
  @ViewChild(SearchIdComponent, { static: false })
  childSearchId!: SearchIdComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  angForm: FormGroup;
  public searchData = 1;
  listSearchData;
  locale = 'en';
  result = 1;
  showList = false;
  bandShowNum;
  bandshowBtn = true;
  country;
  category;
  specialCategory;
  constructor(private servicefilter: SelectFiltreService) { }

  ngOnInit() {
   }

  resultSearchPerson($event) {
    // debugger;
    this.listSearchData = this.childSearchPerson.listSearchData;
    this.result = $event;
    if (this.result === 2) {
      this.showList = true;
    } else {
      this.showList = false;
    }
  }

  resultSearchId($event) {
    this.listSearchData = this.childSearchId.ListItems;
    this.result = $event;
    if (this.result === 2) {
      this.showList = true;
    } else {
      this.showList = false;
    }
  }

  resultComp($event) {
    this.result = $event;
    this.showList = false;

    if (this.result === 2) {
      this.showList = true;
    } else {
      this.showList = false;
    }
  }

  cleanComp($event) {
    this.angForm.reset();
    this.result = $event;
    this.showList = false;
  }
  ShowPaginatDetail($event) {
    this.showList = $event;
  }
  resultBand($event) {
    console.log($event);
    this.bandShowNum = $event;
  }


}
