import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { StripeScriptTag, StripeSource } from 'stripe-angular';
import { StripeToken } from 'stripe-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
import { TranslateService } from '@ngx-translate/core';
import { codigosstripe } from 'src/app/shared/urls/codigosstripe';
import { Global } from 'src/app/core/_service/global';
import { environment } from 'src/environments/environment';

const httpOptions = {
	headers: new HttpHeaders({
		'Content-Type': 'application/json',
	}),
};
@Component({
	selector: 'app-paymentmethod',
	templateUrl: './paymentmethod.component.html',
	styleUrls: ['./paymentmethod.component.scss'],
})
export class PaymentmethodComponent implements OnInit {
	public modificar: boolean = false;
	public show: boolean = false;
	public email;
	public phone;
	public company;
	public pass;
	public plan;
	public uid;
	public idCustomer;
	public metodosdepago: any[];
	public exp_month;
	public exp_year;
	public address_zip;
	public idamodificar;
	public tpredeterminada;
	private publishableKey = codigosstripe;
	public cont = 0;
	idProducto;
	NamePlan;
	invalidError;
	extraData = {
		name: null,
		address_line1: null,
		address_line2: '',
	};
	constructor(
		public VarGlobal: Global,
		private router: Router,
		private cod: codigosstripe,
		private http: HttpClient,
		public StripeScriptTag: StripeScriptTag,
		public afAuth: AngularFireAuth,
		private rutaActiva: ActivatedRoute,
		private translate: TranslateService
	) {
		const uid = this.afAuth.auth.currentUser.uid;
		this.http
			.get(environment.apiback + '/getaccount?id=' + uid)
			.subscribe((y) => {
				var w = JSON.stringify(y);
				var oj = JSON.parse(w);
				const url1 = environment.apiphp + '?idsub=' + oj.idMembresiaStripe;
				this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
					var arre = JSON.parse(x);
					this.NamePlan = arre.items.data[0].plan.nickname;
					this.idProducto = arre.items.data[0].plan.product;
					if (this.idProducto == this.cod.demo) {
						this.router.navigate(['/afterlogin']);
					}
				});
			});
	}

	ngOnInit() {
		this.VarGlobal.SearchCount();

		if (this.idProducto == this.cod.demo) {
			this.router.navigate(['/afterlogin']);
		}
		this.translate.use(localStorage.getItem('language'));
		this.StripeScriptTag.setPublishableKey(this.cod.apistripe);
		//this.uid = localStorage.getItem('uid');
		this.uid = this.afAuth.auth.currentUser.uid;
		this.http
			.get(environment.apiback + '/getaccount?id=' + this.uid)
			.subscribe((y) => {
				var w = JSON.stringify(y);
				var oj = JSON.parse(w);
				this.idCustomer = oj.idCustomer;
				try {
					const url1 =
						environment.apiphp + '?consultarcustomersource=' + this.idCustomer;
					this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
						var arre = JSON.parse(x);
						this.tpredeterminada = arre.default_source;
						this.metodosdepago = arre.sources.data;
					});
				} catch (e) {
					console.log(e.message);
				}
			});
	}

	setStripeToken(token: StripeToken) {
		httpOptions.headers.append('Access-Control-Allow-Origin', '*');
		httpOptions.headers.append('Access-Control-Allow-Methods', '*');
		httpOptions.headers.append(
			'Access-Control-Allow-Headers',
			'X-Requested-With,content-type'
		);
		httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');
		try {
			const url1 =
				environment.apiphp +
				'?source=' +
				token.id +
				'&email=' +
				this.email +
				'&custo=' +
				this.idCustomer +
				'&company=' +
				this.company +
				'&phone=' +
				this.phone;
			this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
				// var j=JSON.parse(x.toString());
				this.ngOnInit();
			});
		} catch (e) {
			console.log(e.message);
		}
	}
	setStripeSource(source: StripeSource) {
		console.log('Stripe source', source);
	}

	onStripeError(error: Error) {
		// console.error('Stripe error', error);
	}
	onStripeInvalid(error: Error) {
		console.log('Validation Error', error);
	}

	mostrartarjeta(id: string) {
		var metodo = this.metodosdepago.find((x) => x.id == id);
		this.idamodificar = id;
		this.modificar = true;
		this.address_zip = metodo.address_zip;
		this.company =
			metodo.metadata.company == 'undefined' ? '' : metodo.metadata.company;
		this.email =
			metodo.metadata.email == 'undefined' ? '' : metodo.metadata.email;
		this.exp_month = metodo.exp_month;
		this.exp_year = metodo.exp_year;
		this.extraData.name = metodo.name;
		this.extraData.address_line1 = metodo.address_line1;
		this.extraData.address_line2 = metodo.address_line2;
		this.phone =
			metodo.metadata.phone == 'undefined' ? '' : metodo.metadata.phone;
	}

	modificarTarjeta() {
		try {
			const url1 =
				environment.apiphp +
				'?cambio=' +
				this.idamodificar +
				'&email=' +
				this.email +
				'&customer=' +
				this.idCustomer +
				'&company=' +
				this.company +
				'&phone=' +
				this.phone +
				'&address_line1=' +
				this.extraData.address_line1 +
				'&email=' +
				this.email +
				'&country=' +
				this.extraData.address_line2 +
				'&exp_year=' +
				this.exp_year +
				'&exp_month=' +
				this.exp_month +
				'&address_zip=' +
				this.address_zip +
				'&nombre=' +
				this.extraData.name;
			this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
				var j = JSON.parse(x.toString());
				this.modificar = false;
			});
		} catch (e) {
			console.log(e.message);
		}
	}

	eliminarTarjeta() {
		try {
			const url1 =
				environment.apiphp +
				'?customer=' +
				this.idCustomer +
				'&eliminar=' +
				this.idamodificar;
			this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
				console.log(x);
				var j = JSON.parse(x.toString());
				this.modificar = false;
				this.ngOnInit();
			});
		} catch (e) {
			console.log(e.message);
		}
	}
	predeterminado() {
		try {
			const url1 =
				environment.apiphp +
				'?customer=' +
				this.idCustomer +
				'&predeterminado=' +
				this.idamodificar;
			this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
				var j = JSON.parse(x.toString());
				this.modificar = false;
				console.log(j);
				this.ngOnInit();
			});
		} catch (e) {
			console.log(e.message);
		}
	}

	changeLeng($event) {
		this.translate.use($event);

		localStorage.setItem('language', $event);
	}
}
