import { Component, OnInit, Inject } from '@angular/core';
import {
	MatDialog,
	MatDialogRef,
	MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { codigosstripe } from '../../../shared/urls/codigosstripe';
import { AuthenticationService } from '../../../core/authentication/Authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Global } from 'src/app/core/_service/global';

export interface DialogData {
	animal: string;
	name: string;
}

@Component({
	selector: 'app-upgrade',
	templateUrl: './upgrade.component.html',
	styleUrls: ['./upgrade.component.scss'],
})
export class UpgradeComponent implements OnInit {
	guid: any;
	idCuenta: any;
	idCustomer: any;
	idMembresia: any;
	idProducto: any;
	prorductName: string;
	uno: boolean = false;
	dos: boolean = false;
	tres: boolean = false;
	cuatro: boolean = false;
	monto: number;
	plan: string;
	arrex: any[];
	animal: string;
	name: string;
	essential: string;
	standard: string;
	pro: string;
	ultra: string;
	constructor(
		public VarGlobal: Global,
		public dialog: MatDialog,
		private cod: codigosstripe,
		private router: Router,
		private http: HttpClient,
		private auth: AuthenticationService,
		private translate: TranslateService,
		public afAuth: AngularFireAuth
	) {
		this.essential = cod.essential;
		this.standard = cod.standard;
		this.pro = cod.pro;
		this.ultra = cod.ultra;
		const url1 = environment.apiphp + '?consultarplanes=true';
		this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
			var cadena = x.replace('Missing required param: source.', '');
			this.arrex = JSON.parse(cadena);
		});

		// this.guid = localStorage.getItem('uid');
		this.guid = this.afAuth.auth.currentUser.uid;
		this.http
			.get(environment.apiback + '/getaccount?id=' + this.guid)
			.subscribe((y) => {
				var w = JSON.stringify(y);
				var oj = JSON.parse(w);
				this.idMembresia = oj.idMembresiaStripe;
				this.idCustomer = oj.idCustomer;
				this.idCuenta = oj.idCuenta;
				const url1 = environment.apiphp + '?idsub=' + this.idMembresia;
				this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
					var arre = JSON.parse(x);
					this.idProducto = arre.items.data[0].plan.product;
					this.prorductName = arre.items.data[0].plan.nickname;
					if (this.idProducto == this.cod.demo) {
						this.router.navigate(['/afterlogin']);
					}
					if (this.idProducto === this.essential) {
						this.dos = true;
						this.tres = true;
						this.cuatro = true;
					} else if (this.idProducto === this.standard) {
						this.tres = true;
						this.cuatro = true;
					} else if (this.idProducto === this.pro) {
						this.cuatro = true;
					}
				});
			});
	}

	ngOnInit() {
		this.VarGlobal.SearchCount();

		if (this.idProducto == this.cod.demo) {
			this.router.navigate(['/afterlogin']);
		}
		this.translate.use(localStorage.getItem('language'));
	}

	upgrade(prod: string) {
		// this.openDialog();
		this.arrex.forEach((x) => {
			if (prod === x.product) {
				if (
					confirm(
						'Desea migrar al plan: ' +
							x.nickname +
							' por el monto de: ' +
							x.amount / 100
					)
				) {
					const url3 =
						environment.apiphp +
						'?upgrade=true' +
						'&subsid=' +
						this.idMembresia +
						'&customerx=' +
						this.idCustomer +
						'&planx=' +
						x.id;
					this.http.get(url3, { responseType: 'text' }).subscribe((xt) => {
						const cadena = xt.replace('Missing required param: source.', '');
						/*  console.log(xt);
             // this.arrex = JSON.parse(cadena);
             console.log(x.id); */
						console.log(x.product);
						let cant = 0;
						if (x.product === this.essential) {
							cant = 5;
						} else if (x.product === this.standard) {
							cant = 15;
						} else if (x.product === this.pro) {
							cant = 50;
						} else if (x.product === this.ultra) {
							cant = 100;
						}

						const dataX = {
							IDCuenta: this.idCuenta,
							Verificaciones: cant,
						};
						const url3 = environment.apiback + '/registerPackCuenta';
						this.http.post(url3, dataX).subscribe((y) => {
							localStorage.setItem('plan', x.product);
							this.router.navigate([
								'/agradecimiento',
								'Gracias adquirir nuevo Plan',
								x.nickname,
							]);
						});
					});
				} else {
				}
			}
		});
	}

	changeLeng($event) {
		this.translate.use($event);

		localStorage.setItem('language', $event);
	}
}
