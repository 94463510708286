import { Component, OnInit } from '@angular/core';
import { FullScreenOverlayComponent } from 'src/app/shared/components/full-screen-overlay/full-screen-overlay.component';

@Component({
  selector: 'app-workspace-seetings',
  templateUrl: './workspace-seetings.component.html',
  styleUrls: ['./workspace-seetings.component.scss']
})
export class WorkspaceSeetingsComponent  implements OnInit {

  ngOnInit() {
  }

}
