export class AddonsService {
  precio: string;
  nombre: string;
  tipo: number;
  cupos: number;
  cursos: number;
  examen: number;
  certificado: number;

  constructor($n, $p, $t, $cup, $cur, $exa, $cer) {
    this.precio = $p;
    this.nombre = $n;
    this.tipo = $t;
    this.cupos = $cup;
    this.cursos = $cur;
    this.examen = $exa;
    this.certificado = $cer;
  }
}
