import { Injectable } from '@angular/core';
//import { url } from '../../shared/urls/url2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Datostabla } from '../../shared/datos-tabla';
@Injectable({
	providedIn: 'root',
})
export class RecurringSearchService {
	constructor(private http: HttpClient) {}
	httpOptions = {
		headers: new HttpHeaders({
			Authorization: 'Bearer ' + localStorage.getItem('TokenServe'),
			responseType: 'arraybuffer',
		}),
	};

	public BusquedaPorUsuario(): Observable<any> {
		const urlbase = `${environment.apibackdavid}/api/Search/continuous-search`;
		return this.http.post<any>(urlbase, {}, this.httpOptions);
	}

	public BusquedaPorUsuario2(
		sort: string,
		order: string,
		PageSize: number,
		page: number
	): Observable<Datostabla> {
		const urlbase = `${environment.apibackdavid}/api/Search/continuous-search/${PageSize}/${page}`;
		return this.http.get<Datostabla>(urlbase, this.httpOptions);
	}
}
