import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import 'jspdf-autotable';
import { Base64Img } from 'src/app/core/_service/Base64img';
import { datepickerAnimation } from 'ngx-bootstrap/datepicker/datepicker-animations';
import { Router } from '@angular/router';
@Component({
	selector: 'app-mass-search-results',
	templateUrl: './mass-search-results.component.html',
	styleUrls: ['./mass-search-results.component.scss'],
})
export class MassSearchResultsComponent implements OnInit {
	constructor(private router: Router) {}
	MasssiveSearch;
	showDetailResult = true;
	DataResult;
	showBackbtn = false;
	noResult = false;
	bandShowNum;
	nameCol;
	logoRisk = 'assetspictureslogo-ai.png';
	LogoDowJones = 'assetspicturesDOW-JONES.png';

	@Output() public showNewSearch = new EventEmitter<boolean>();
	@Input() public result;
	@Input() public typeList;
	@Input() public typeSearch;

	ngOnInit() {
		if (this.typeSearch === 2) {
			const dataType = <any[]>JSON.parse(localStorage.getItem('IdTypeSearch'));
			const searchID = <any[]>JSON.parse(localStorage.getItem('DataSearch'));
			console.log(searchID);
			const data1 = dataType.filter((it) => {
				return it.value === searchID['idTypeKey'];
			});
			this.nameCol = data1[0]['description'];
		}
	}
	getshowresult(message: boolean) {
		this.MasssiveSearch = message;
	}

	generarPDF() {
		const doc = new jsPDF();
		const totalPagesExp = '{total_pages_count_string}'; // placeholder

		// Header
		doc.setFontSize(12);
		const fileTitle = 'Criterio de Búsqueda';
		const img = new Base64Img();
		// doc.text(fileTitle, 14, 35);
		doc.addImage(img.riskyId, 'JPEG', 10, 2, 45, 45);
		doc.addImage(img.dowjson, 'JPEG', 152, 22, 37, 6);
		// Footer
		const pageSize = doc.internal.pageSize;
		// jsPDF 1.4+ uses getHeight, <1.4 uses .height
		const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
		// jsPDF 1.4+ uses getWidth, <1.4 uses .width
		// const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

		let str = 'Page ' + doc.internal.getNumberOfPages();
		// Total page number plugin only available in jspdf v1.0+
		if (typeof doc.putTotalPages === 'function') {
			str = str + ' of ' + totalPagesExp;
		}
		doc.setFontSize(10);
		// doc.text(str, data.settings.margin.left, pageHeight - 10);

		// doc.addImage(img2, 'JPEG', 150, 10, 20, 20);
		const dataSearch = JSON.parse(localStorage.getItem('DataSearch'));

		const columns = [fileTitle];

		const rows = [
			[
				'Tipo de Búsqueda: ' +
					(dataSearch.searchType === '0'
						? 'Precisa'
						: dataSearch.searchType === '1'
						? 'Cerca'
						: 'Amplia'),
			],
			['Porcentaje de búsqueda: ' + dataSearch.porcentaje],
			['Región: ' + dataSearch.filterRegion],
			['PEP Categoría: ' + dataSearch.filterPep],
			['Categoría de interés especial: ' + dataSearch.filterSic],
			['Fecha de búsqueda: ' + dataSearch.DateLocal],
		];

		doc.autoTable(columns, rows, {
			theme: 'plain',
			startY: pageHeight - 260,
			tableLineWidth: 0.09,
			tableLineColor: [0, 0, 0],
			styles: {
				overflow: 'linebreak',
				columnWidth: 'wrap',
			},
			headStyles: {
				fillStyle: 'S',
				valign: 'middle',
				fontStyle: 'bold',
				fontSize: 10,
			},
		});

		// doc.text('Tipo de Búsqueda: ' + (dataSearch.searchType === '0' ? 'Precisa' :
		//   (dataSearch.searchType === '1' ? 'Cerca' : 'Amplia')), 14, 40);
		// doc.text('Porcentaje de búsqueda: ' + dataSearch.porcentaje, 14, 45);
		// doc.text('Región: ' + dataSearch.filterRegion, 14, 50);
		// doc.text('PEP Categoría: ' + dataSearch.filterPep, 14, 55);
		// doc.text('Categoría de interés especial: ' + dataSearch.filterSic, 14, 60);
		// doc.text('Fecha de búsqueda: ' + dataSearch.DateLocal, 14, 65);
		doc.rect(14.2, 37.2, 181.5, 7);
		doc.rect(14.2, 90, 181.5, 7);
		doc.setFontType('bold');
		doc.text('Resultado de búsqueda', 16, 94);
		doc.autoTable({
			html: '#tableexample',
			startY: pageHeight - 200,
			theme: 'plain',
			styles: {
				overflow: 'linebreak',
				columnWidth: 'wrap',
				lineWidth: 0.01,
			},
			bodyStyles: { lineColor: [0, 0, 0] },
			headStyles: {
				lineColor: [0, 0, 0],
				cellPadding: 0,
				rowHeight: 10,
				fillStyle: 'S',
				halign: 'center',
				valign: 'middle',
				fontStyle: 'bold',
				lineWidth: 0.01,
				fontSize: 10,
			},
		});
		doc.save('ResultPDF.pdf');
	}

	showDetail(data, name) {
		let dataSearch = JSON.parse(localStorage.getItem('DataSearch'));
		dataSearch.name = name;
		localStorage.setItem('DataSearch', JSON.stringify(dataSearch));
		this.showDetailResult = false;
		this.DataResult = data;
		localStorage.setItem('massive', 'massive');
	}

	resultComp($event) {
		//location.reload();
		//this.showDetailResult = true;
		//this.noResult = false;
		// this.showNewSearch.emit(false);
		this.showNewSearch.emit(false);
	}

	cleanComp($event) {
		//	this.showDetailResult = true;
		//	this.showNewSearch.emit(false);

		this.showDetailResult = true;
		this.noResult = false;
	}

	newSearchEven() {
		this.showNewSearch.emit(false);
	}
	showNoResultDetail(type, searchPerson) {
		debugger;
		this.noResult = true;
		this.bandShowNum = type;
		this.showDetailResult = false;
		const dataSearch = JSON.parse(localStorage.getItem('DataSearch'));

		dataSearch.name = searchPerson.name;
		dataSearch.dateOfBirth = searchPerson.dateOfBirth;

		localStorage.setItem('DataSearch', JSON.stringify(dataSearch));
	}

	ShowPaginatDetail($event) {
		// this.noResult = $event;
		//console.log($event);
	}
}
