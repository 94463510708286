import { Component } from '@angular/core';
import * as firebase from 'firebase/app';
const config = {
  apiKey: "AIzaSyBTLEynebc8LU-dGEax08ZMuAPClrCZEWw",
  authDomain: "zabio-b054a.firebaseapp.com",
  databaseURL: "https://zabio-b054a.firebaseio.com",
  projectId: "zabio-b054a",
  storageBucket: "zabio-b054a.appspot.com",
  messagingSenderId: "457132097128",
  appId: "1:457132097128:web:f5f2faace5f215f4",
  measurementId: "G-924Y57XSLN"
};
firebase.initializeApp(config);
firebase.analytics();
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Risky ID';
}
