import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-call-center',
  templateUrl: './call-center.component.html',
  styleUrls: ['./call-center.component.scss']
})
export class CallCenterComponent implements OnInit {

  constructor() { }
  @Output() public BackEvent = new EventEmitter<Number>();

  ngOnInit() {
  }
  clickBack() {
    this.BackEvent.emit(0);
  }
}
