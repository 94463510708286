import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
export class Plans {
	//public typePlan: string;

	constructor(private http: HttpClient, private afAuth: AngularFireAuth) {}

	LoadPackUser() {
		// const id = this.afAuth.auth.currentUser.uid;
		const guid = this.afAuth.auth.currentUser.uid;
		this.http
			.get(environment.apiback + '/getaccount?id=' + guid)
			.subscribe((y) => {
				const w = JSON.stringify(y);
				const oj = JSON.parse(w);
				const idMembresia = oj.idMembresiaStripe;
				const idCustomer = oj.idCustomer;
				const idCuenta = oj.idCuenta;
				const url1 = environment.apiphp + '?idsub=' + idMembresia;
				this.http.get(url1, { responseType: 'text' }).subscribe((x) => {
					const arre = JSON.parse(x);
					const idProducto = arre.items.data[0].plan.product;
					// console.log(this.idProducto);

					return idProducto;
				});
			});
	}
}
