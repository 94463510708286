import { SearchService } from 'src/app/core/Search/search.service';
import { AngularFireAuth } from '@angular/fire/auth';

export class Global {
    CoutValid = 0;

    constructor(private search: SearchService, private afAuth: AngularFireAuth) {
        this.SearchCount();
    }

    SearchCount() {
        const guid = this.afAuth.auth.currentUser.uid;
        this.search.GetAccount(guid).subscribe(y => {
            const w = JSON.stringify(y);
            const oj = JSON.parse(w);
            const idCuenta = oj.idCuenta;

            this.search.Verifications(idCuenta).subscribe((res) => {

                this.CoutValid = +res;

            });
        });
    }
}
