import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/core/_helpers/Language';
import { codigosstripe } from '../../../../shared/urls/codigosstripe';
@Component({
  selector: 'app-planes-membresia',
  templateUrl: './planes-membresia.component.html',
  styleUrls: ['./planes-membresia.component.scss']
})
export class PlanesMembresiaComponent implements OnInit {
  essential:string;
  standard:string;
  pro:string;
  ultra:string;
  pack:string;
  pack1:string;
  pack2:string;
  constructor(private cod: codigosstripe,private translate: TranslateService, private lenguaje: Language)
   {
    this.essential = cod.essential;
    this.standard = cod.standard;
    this.pro = cod.pro;
    this.ultra = cod.ultra;
    this.pack = cod.packs;
    this.pack1 = cod.planpack1;
    this.pack2 = cod.planpack2;
  }

  ngOnInit() {}

  translateLeng() {
    const lang = localStorage.getItem('language');
    this.lenguaje.IniLenguaje(lang);
  }

  	changeLeng($event) {
		this.translate.use($event);

		localStorage.setItem('language', $event);
	}

}
